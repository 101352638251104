import { Button, Divider, Stack, Typography } from '@mui/material';
import { ReactComponent as SequenceIcon } from 'src/assets/icons/sequence.svg';
import MailIcon from '@mui/icons-material/Mail';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

const SequenceEmpty = ({openForm}) => {
  return (
    <Stack marginTop={"80px"}>
      <Stack
        gap={'40px'}
        sx={{
          borderTop: '7px solid #13DEA1',
          borderTopRightRadius: '7px',
          borderTopLeftRadius: '7px',
          padding: '80px',
        }}
      >
        <Stack gap={2} direction={'row'} alignItems={'center'}>
          <SequenceIcon />
          <Typography color="#1A1A1A" fontWeight={500} fontSize={'20px'}>
            Creating a sequence = your first step to smarter outreach!{' '}
          </Typography>
        </Stack>
        <Stack gap={'40px'} direction="row" width="100%">
          <Stack gap={'40px'} width={'60%'}>
            <Typography
              lineHeight={'202%'}
              color="#666666"
              fontWeight={400}
              fontFamily={'Roboto !important'}
            >
              Kickstart your outreach with ease! Creating a sequence takes just minutes and helps
              you engage prospects faster, stay consistent, and close more deals.
              <br />
              <br />
              Save time while keeping your outreach personal and effective.
            </Typography>
            <Button
              sx={{
                height: '40px',
                width: 'fit-content',
                padding: '0 16px',
                borderRadius: '5px',
                border: '1px solid #1976D2',
              }}
              onClick={() => openForm()}
            >
              <Typography fontSize={'14px'} fontWeight={500} color={'#1976D2'}>
                Get Started Now
              </Typography>
            </Button>
          </Stack>
          <Stack
            width="40%"
            padding="0 40px 0 40px"
            sx={{
              svg: {
                color: '#3C9EFF',
              },
              '& .optionContainer': {
                display: 'flex',
                alignSelf: 'stretch',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '12px',
                flexDirection: 'row',
                gap: '8px',
                bgcolor: 'rgba(173, 173, 173, 0.15)',
              },
              '& .plus-container': {
                display: 'flex',
                alignSelf: 'stretch',
                alignItems: 'center',
                justifyContent: 'center',
              },
              '& .whiteSlide': {
                height: '12px',
                bgcolor: '#FFFFFFCC',
                borderRadius: '999px',
                alignSelf: 'stretch',
              },
            }}
          >
            <Stack gap={'8px'} alignItems={'start'}>
              <Stack className="optionContainer">
                <MailIcon />
                <Stack flex={1} gap={'8px'}>
                  <Stack gap={'8px'} flex={1}>
                    <Stack className="whiteSlide" />
                    <Stack className="whiteSlide" width={'40%'} />
                  </Stack>
                </Stack>
              </Stack>
              <Stack className="plus-container">
                <AddCircleIcon />
              </Stack>
              <Stack className="optionContainer">
                <LinkedInIcon />
                <Stack flex={1} gap={'8px'}>
                  <Stack gap={'8px'} flex={1}>
                    <Stack className="whiteSlide" />
                    <Stack width={'40%'} className="whiteSlide" />
                  </Stack>
                </Stack>
              </Stack>
              <Stack className="plus-container">
                <AddCircleIcon />
              </Stack>
              <Stack className="optionContainer">
                <PhoneCallbackIcon />
                <Stack flex={1} gap={'8px'}>
                  <Stack gap={'8px'} flex={1}>
                    <Stack className="whiteSlide" />
                    <Stack width={'40%'} className="whiteSlide" />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SequenceEmpty;
