import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Paper, Container, CircularProgress, Stack } from '@mui/material';
import { TabContext } from '@mui/lab';

import { Spacer } from 'src/components/shared';
import { Tabs } from 'src/components/App';

import DetailsForm from '../../components/DetailsForm';
import AccountsForm from '../../components/AccountsForm';
import Voice from '../../components/Voice';
import ICP from '../../components/ICP';
import SchedulingWindow from '../../components/ConnectEmail/SchedulingWindow';
import Security from '../../components/security/Security';
import ChangePassword from '../../components/security/ChangePassword';
import CustomVariables from '../../../app/components/CustomVariables';
import Notification from '../../components/Notification';

import { styled } from '@mui/material/styles';
import { ProfileTabs, ProfileTabsLms, ProfileLmsOutTabs } from '../../config';

import { saveRescheduleEmail, updateSettings } from './../../../admin/actions/adminActions';
import {
  putUser,
  fetchUsers,
  uploadImage,
  resetPassword,
  setNLToken,
  disconnectIntegration,
  deleteProfileImage,
} from '../../actions/authActions';
import { fetchUserById } from 'src/modules/admin/api/adminApi';

const nyLasLoginError = ['error_code', 'error', 'error_description'];

function MyProfileView({ contacts, newTab, ...props }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [tab, setTab] = useState('details');
  const currentTab = useRef({});
  const params = useParams();
  const history = useHistory();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const isOtherUser = new URLSearchParams(search).get('otheruser');
  const userid = props.user?.id;
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otherProfileInfo, setOtherProfileInfo] = useState({});
  const tenant = useSelector((state) => state.app.tenant);
  const hideProfileTab = useSelector((state) => state.auth.hideProfileTab);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const hasNyLasLoginError = nyLasLoginError.some((key) => searchParams.has(key));
    if (hasNyLasLoginError) {
      history.push('/profile/outreachSettings');
    }

    // /profile/email is old route, redirect to /profile/outreachSettings
    if (history?.location?.pathname === '/profile/email') {
      history.push('/profile/outreachSettings');
    }
  }, [search, history, nyLasLoginError]);

  useEffect(() => {
    if (code && userid) {
      setLoading(true);
      props
        .setNLToken(code, userid)
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [code, params.id]);

  useEffect(() => {
    // if (isOtherUser && params.id) {
    if (params.id && params.id !== '') {
      fetchUserById(params.id)
        .then((r) => setOtherProfileInfo(r.user))
        .catch((error) => console.error('Error: ', error));
    } else if (params.id) {
      // getUserDetailsForAdmin(params.id);
    }
  }, [params.id]);

  const currentUser = useMemo(() => {
    if (props?.match?.params?.id) {
      return otherProfileInfo;
    } else {
      return props.user;
    }
  }, [props?.match, otherProfileInfo, props.user]);

  const hasLmsAccess = useMemo(() => {
    return currentUser?.lmsmonthly === true || currentUser?.lmsannual === true;
  }, [currentUser]);

  const hasOutreachAccess = useMemo(() => {
    if (
      !currentUser?.outreachAnnual &&
      !currentUser?.outreachMonthly &&
      !currentUser?.lmsmonthly &&
      !currentUser?.lmsannual
    ) {
      return true;
    }
    return currentUser?.outreachAnnual === true || currentUser?.outreachMonthly === true;
  }, [currentUser]);

  const filterTabs = (tabsList, tenant) => {
    return tabsList.filter((item) => {
      const { generateToneOfVoiceWithAI = false, hasICPAccess = false } =
        tenant?.featureAccess || {};
      if (!generateToneOfVoiceWithAI && item.id === 'voice') return false;
      return !(!hasICPAccess && item.id === 'icp');
    });
  };

  const getTabsBaseOnAccess = (hasOutreachAccess, hasLmsAccess) => {
    if (hasOutreachAccess && hasLmsAccess) return ProfileLmsOutTabs;
    if (hasOutreachAccess) return ProfileTabs;
    return ProfileTabsLms;
  };

  const tabs = useMemo(() => {
    const tabsList = getTabsBaseOnAccess(hasOutreachAccess, hasLmsAccess);
    return filterTabs(tabsList, tenant);
  }, [hasOutreachAccess, hasLmsAccess, tenant]);

  const refreshCurrentUserInfo = useCallback((currentUserId) => {
    setIsFirstTime(true);
    props.fetchUsers(currentUserId);
  });

  const getUserDetailsForAdmin = useCallback((userId) => {
    props.fetchUsers(userId);
  });

  useEffect(() => {
    const segments = history?.location?.pathname.split('/');
    const tabId = segments.pop() || segments.pop();
    const index = ProfileTabs.findIndex((item) => item.id === tabId);
    setTabIndex(index);
    setTab(tabId);
  }, [history?.location?.pathname]);

  const onTabChange = (tab, index) => {
    if (params.id) {
      setTab(tab.id);
      setTabIndex(index);
      currentTab.current = tab;
      history.push(`/admin/user/${params.id || props.user?.id}/${tab.id}`);
    } else {
      setTab(tab.id);
      setTabIndex(index);
      currentTab.current = tab;
      history.push(`/profile/${tab.id}`);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    flexDirection: 'initial',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    gridRow: '1',
    gridColumn: 'span 3',
  }));

  const updateUser = async (payload) => {
    try {
      const res = await props.putUser(payload);
      setOtherProfileInfo(res.user);
    } catch (error) {}
  };
  const isOtherProfileInfoExist = !!Object.keys(otherProfileInfo).length;

  const hideContainerMaxWidth = ['voice', 'icp'];

  return (
    <>
      <TabContext value={tab} sx={{}}>
        {!hideProfileTab && <Tabs tabs={tabs} onChange={onTabChange} value={tab} />}
        {tab === 'voice' || <Spacer y={2} x={2} />}
        <Container
          disableGutters
          maxWidth={hideContainerMaxWidth.includes(tab) ? 'none' : 'lg'}
          sx={{ marginLeft: 0 }}
        >
          {loading && (
            <>
              <Spacer y={4} x={4} />
              <Stack direction={'row'} justifyContent={'center'}>
                <CircularProgress size={30} color="secondary" />
              </Stack>
            </>
          )}

          {tab === 'details' ? (
            <Item>
              <DetailsForm
                profileImage={isOtherProfileInfoExist ? otherProfileInfo : props.user}
                editUser={currentUser}
                status={props.status}
                userImg={props.profileImage}
                deleteProfileImage={props.deleteProfileImage}
                {...props}
                putUser={updateUser}
                hasLmsAccess={hasLmsAccess}
                hasOutreachAccess={hasOutreachAccess}
              />
            </Item>
          ) : null}

          {tab === 'account' ? (
            <Item>
              <AccountsForm
                editUser={currentUser}
                status={props.status}
                total={props.total}
                officeTotal={props.officeTotal}
                teamsTotal={props.teamsTotal}
                {...props}
                putUser={updateUser}
              />
            </Item>
          ) : null}

          {tab === 'voice' ? (
            <>
              <Voice
                editUser={currentUser}
                status={props.status}
                total={props.total}
                officeTotal={props.officeTotal}
                teamsTotal={props.teamsTotal}
                {...props}
                putUser={updateUser}
              />
            </>
          ) : null}

          {tab === 'icp' ? (
            <>
              <Spacer y={-2} x={-2} />
              <ICP
                editUser={currentUser}
                status={props.status}
                total={props.total}
                officeTotal={props.officeTotal}
                teamsTotal={props.teamsTotal}
                {...props}
                putUser={updateUser}
              />
            </>
          ) : null}

          {tab === 'outreachSettings'
            ? props && (
                <SchedulingWindow
                  editUser={currentUser}
                  Container={Item}
                  status={props.status}
                  {...props}
                  putUser={updateUser}
                />
              )
            : null}

          {tab === 'customVariables' ? <CustomVariables /> : null}

          {tab === 'notification' ? (
            <Notification
              editUser={currentUser}
              status={props.status}
              {...props}
              putUser={updateUser}
            />
          ) : null}

          {tab === 'security' ? (
            <>
              <Item>
                <ChangePassword
                  {...props}
                  user={isOtherProfileInfoExist ? otherProfileInfo : props.user}
                  editUser={isOtherProfileInfoExist ? otherProfileInfo : props.user}
                  status={props.status}
                  putUser={updateUser}
                />
              </Item>
              {props?.isAdmin ? (
                <>
                  <Spacer x={4} y={4} />
                  <Item>
                    <Security
                      editUser={currentUser}
                      status={props.status}
                      isAdmin={props?.isAdmin}
                      {...props}
                      putUser={updateUser}
                    />
                  </Item>
                </>
              ) : null}
            </>
          ) : null}
        </Container>
      </TabContext>
    </>
  );
}

const mapStateToProps = (state) => ({
  globals: state.app.globals,
  status: state.app.status,
  isAdmin: state.auth.user.isAdmin,
  user: state.auth.user,
  profileImage: state.auth.profileImage,
  officeTotal: state.admin.admin.offices?.paging.count,
  teamsTotal: state.admin.admin.teams?.paging.count,
});

const mapDispatchToProps = (dispatch) => ({
  putUser: (userData) => {
    return new Promise((resolve, reject) => {
      dispatch(putUser(userData, resolve, reject));
    });
  },
  resetPassword: (userData) => {
    return new Promise((resolve, reject) => {
      dispatch(resetPassword(userData, resolve, reject));
    });
  },
  fetchUsers: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchUsers(id, resolve, reject));
    });
  },
  uploadImage: (file) => {
    return new Promise((resolve, reject) => {
      dispatch(uploadImage(file, resolve, reject));
    });
  },
  deleteProfileImage: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteProfileImage(id, resolve, reject));
    });
  },
  setNLToken: (code, userid) => {
    return new Promise((resolve, reject) => {
      dispatch(setNLToken(code, userid, resolve, reject));
    });
  },
  disconnectIntegration: (id, status) => {
    return new Promise((resolve, reject) => {
      dispatch(disconnectIntegration(id, status, resolve, reject));
    });
  },
  saveRescheduleEmail: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(saveRescheduleEmail(data, resolve, reject));
    });
  },
  updateSettings: (data, id) => dispatch(updateSettings(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileView);
