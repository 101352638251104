import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';
import cookieUtils from 'src/utils/cookieUtils';
import { useSelector } from 'react-redux';

const ChromeExtensionBanner = () => {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const chromeExtensionBannerStyles = useChromeExtensionBannerStyles();
  const showChromeExtensionBanner = cookieUtils.get('CHROME_EXTENSION_BANNER');

  const [isShowChromeExtensionBanner, setIsShowChromeExtensionBanner] = useState(
    !showChromeExtensionBanner,
  );

  const isShowBanner = useMemo(
    () =>
      isShowChromeExtensionBanner &&
      user?.outreachJourneyCompleted &&
      !user?.downloadExtension &&
      !user?.firstExtenstionLogin,
    [isShowChromeExtensionBanner],
  );

  const redirectOnChromeExtension = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/superreach-sales-hiring-a/nfjemjifleiijedpmipbgbpefcopdkci?hl=en',
    );
  };

  const removeSubscriptionBanner = () => {
    cookieUtils.set('CHROME_EXTENSION_BANNER', true, 1);
    setIsShowChromeExtensionBanner(false);
  };

  return isShowBanner ? (
    <Stack className={chromeExtensionBannerStyles.root}>
      <Stack
        className="banner"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
        p={1.25}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap={3}
          flex={1}
          pl={{ xs: 0, xl: 6 }}
        >
          <Typography variant="body2" fontWeight={500}>
            Get the Chrome extension to unlock more features on Outreach.
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={redirectOnChromeExtension}
          >
            Download Chrome
            <EastIcon fontSize="small" sx={{ ml: 0.5 }} />
          </Button>
        </Stack>
        <Stack sx={{ cursor: 'pointer' }} onClick={removeSubscriptionBanner}>
          <CloseIcon className="close-icon" />
        </Stack>
      </Stack>
    </Stack>
  ) : null;
};

export default ChromeExtensionBanner;

const useChromeExtensionBannerStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 50,
    zIndex: 1000,
    background: theme.palette.background.default,

    '& .banner': {
      background:
        'linear-gradient(233.11deg, rgba(255, 212, 128, 0.3) 6.49%, rgba(242, 64, 164, 0.3) 38.69%, rgba(25, 118, 210, 0.3) 80.84%)',
    },

    '& .close-icon': {},
  },
}));
