import React, { useMemo } from 'react';
import { Box, Card, Stack, Tooltip, Typography } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { dateTimeFormat } from 'src/config';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';

function ConsultantReport({ data, loading }) {
  const columns = useMemo(() => {
    if (!data.length) {
      return [];
    }
    let colData = Object.keys(data[0]);
    return colData.map((item) => {
      return {
        label:
          item === 'consultant' ? (
            <Stack alignItems={'center'} direction={'row'} gap={'8px'}>
              <Typography variant="body2" color="textSecondary">
                User
              </Typography>
              <Tooltip title={'The user who initiated the data request.'} placement="bottom">
                <InfoIcon style={{ fill: '#a3a3a3' }} />
              </Tooltip>
            </Stack>
          ) : item === 'requests' ? (
            <Stack alignItems={'center'} direction={'row'} gap={'8px'}>
              <Typography variant="body2" color="textSecondary">
                Total requests
              </Typography>
              <Tooltip title={'Total number of data requests made by the user.'} placement="bottom">
                <InfoIcon style={{ fill: '#a3a3a3' }} />
              </Tooltip>
            </Stack>
          ) : item === 'spend' ? (
            <Stack alignItems={'center'} direction={'row'} gap={'8px'}>
              <Typography variant="body2" color="textSecondary">
                Charges
              </Typography>
              <Tooltip
                title={'Total charges for all the data requests.'}
                placement="bottom"
              >
                <InfoIcon style={{ fill: '#a3a3a3' }} />
              </Tooltip>
            </Stack>
          ) : item === 'found' ? (
            <Stack alignItems={'center'} direction={'row'} gap={'8px'}>
              <Typography variant="body2" color="textSecondary">
                Data found
              </Typography>
              <Tooltip title={'The count of successful data obtained.'} placement="bottom">
                <InfoIcon style={{ fill: '#a3a3a3' }} />
              </Tooltip>
            </Stack>
          ) : null,
        name: item,
        options: {
          sort: false,
        },
        ...(item === 'date' && {
          options: {
            setCellHeaderProps: (value) => ({
              style: { minWidth: '140px' },
            }),
            setCellProps: () => ({
              style: { minWidth: '140px' },
            }),
            customBodyRenderLite: (index) => {
              const rData = data[index];
              let date = rData[item];
              if (date && date !== '') return moment.utc(date).local().format(dateTimeFormat);
              else return '-';
            },
          },
        }),
        ...(item === 'activity' && {
          options: {
            customBodyRenderLite: (index) => {
              const rData = data[index];
              return <div dangerouslySetInnerHTML={{ __html: rData?.activity || '' }}></div>;
            },
          },
        }),
      };
    });
  }, [data]);

  return (
    <Card>
      <Box>
        <AppGrid
          selectableRows="none"
          columns={columns}
          data={data}
          loading={loading}
          options={{
            pagination: true,
          }}
        />
      </Box>
    </Card>
  );
}

export default ConsultantReport;
