import React, { useMemo, useState } from 'react';
import { Card, CardContent, CardActions, Typography, Switch, Box, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  BlackChip,
  BlackChipText,
  CardMoreOption,
  CustomerCard,
  FieldCardChip,
  FieldChipText,
  ICPCardViewsGrid,
  IcpModalContent,
  useStyles,
} from './style';
import images from '../../../../config/images';
import {
  cardLabelList,
  fieldList,
  getBackGroundColor,
  getFieldValue,
  getFieldValueForMoreDetail,
} from './utils';
import IcpModal from './IcpModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import toast from '../../../../utils/toast';
import Menu from '../../../../components/shared/Menu';

const ICPCard = ({
  data,
  setIsDetailModal,
  setShowICPForm,
  updateICP,
  deleteICP,
  icpsData,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const theme = useTheme();
  const classes = useStyles();

  const updatedFieldList = useMemo(
    () =>
      fieldList
        .map((field) => {
          if (data?.hasOwnProperty(field.key)) {
            return {
              ...field,
              value: data[field.key],
            };
          }
          return field;
        })
        .filter((field) => field?.value !== undefined && field?.value !== null),
    [data],
  );

  const moreDetailList = useMemo(() => {
    const valueList = [];
    fieldList.map((field) => {
      if (data?.hasOwnProperty(field?.key)) {
        const fieldValueData = getFieldValueForMoreDetail(data[field?.key]);
        if (Array.isArray(fieldValueData)) {
          fieldValueData.map((item) => {
            valueList.push({
              ...field,
              value: item,
            });
          });
        } else {
          valueList.push({
            ...field,
            value: fieldValueData,
          });
        }
      }
      return field;
    });
    return valueList.filter((item) => item?.value);
  }, [data]);

  const cardFields = useMemo(() => {
    return updatedFieldList?.filter((field) => cardLabelList.includes(field?.key));
  }, [updatedFieldList]);

  const handleUpdateICP = async () => {
    try {
      const updateValue = icpsData.find((v) => v?.id === data.id);
      updateValue.status = !data.status;
      await updateICP(updateValue);
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
      toast.success(`ICP ${!data.status ? 'activated' : 'deactivated'} successfully`);
      setIsOpenModal(false);
    } catch (error) {
      toast.error(`ICP ${!data.status ? 'activated' : 'deactivated'} failed`);
      setIsOpenModal(false);
      console.error(error);
    }
  };

  const handelDeleteICP = async () => {
    try {
      await deleteICP(data.id);
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
      toast.success('ICP deleted successfully');
      setIsOpenDeleteModal(false);
    } catch (error) {
      setIsOpenDeleteModal(false);
      console.error(error);
    }
  };

  const getUpdatedFieldList = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} width="100%" gap={'8px'}>
        {cardFields &&
          cardFields?.map((field) => {
            const showMoreButton = cardFields?.at(-1)?.value === field?.value;
            return (
              <Box
                display={showMoreButton ? 'flex' : 'block'}
                justifyContent={showMoreButton ? 'space-between' : ''}
                columnGap={'10px'}
              >
                <FieldCardChip sx={{ width: 'fit-content !important' }}>
                  <img src={images.icp[field?.icon]} alt={''} />
                  <FieldChipText>{getFieldValue(field?.value)}</FieldChipText>
                </FieldCardChip>
                {(updatedFieldList.length > 3 || moreDetailList?.length > 3) && showMoreButton && (
                  <CardMoreOption
                    onClick={() => setIsDetailModal({ isOpen: true, moreDetails: data })}
                  >
                    {moreDetailList.length - 3} more{' '}
                    <ArrowForwardIcon fontSize={'16px'} sx={{ marginLeft: '4px' }} />
                  </CardMoreOption>
                )}
              </Box>
            );
          })}
      </Box>
    );
  };

  return (
    <>
      <Card className={classes.icpCardContainer}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            sx={{ background: getBackGroundColor(data?.profileType) }}
            padding="12px"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormControlLabel
                sx={{ marginLeft: '0px !important' }}
                control={
                  <Switch
                    checked={data.status}
                    onChange={() => setIsOpenModal(true)}
                    color="secondary"
                    size="small"
                  />
                }
                label=""
              />
              <Box sx={{ '& .MuiCardActions-root': { padding: '0px !important' } }}>
                <CardActions disableSpacing>
                  <Box
                    sx={{
                      '& .MuiMenu-paper': {
                        borderRadius: '8px !important',
                      },
                      '& .MuiList-root': {
                        padding: '0px !important',
                      },
                    }}
                  >
                    <Menu
                      removeParentAnchorlEl={setAnchorEl}
                      sx={{
                        '& .MuiMenu-paper': {
                          borderRadius: '8px !important',
                        },
                        '& .MuiList-root': {
                          padding: '0px !important',
                        },
                      }}
                      menuItemSx={{ padding: '8px  16px' }}
                      iconButtonColor={Boolean(anchorEl) ? 'white' : 'rgba(13, 12, 10, 0.5)'}
                      buttonStyles={{
                        padding: '0px !important',
                        background: Boolean(anchorEl) ? 'rgba(13, 12, 10, 0.5)' : '',
                        borderRadius: '4px',
                      }}
                      options={[
                        {
                          label: 'Edit',
                          labelSx: {
                            color: theme.palette.text.grey,
                            fontSize: '16px',
                            fontWeight: '500',
                          },
                          icon: (
                            <img src={images.icp.editIcon} alt="" width={'24px'} height={'24px'} />
                          ),
                          onClick: () => {
                            setShowICPForm({ isOpen: true, edit: true, clone: false, id: data.id });
                          },
                        },
                        {
                          label: 'Clone',
                          labelSx: {
                            color: theme.palette.text.grey,
                            fontSize: '16px',
                            fontWeight: '500',
                          },
                          icon: (
                            <img src={images.icp.cloneIcon} alt="" width={'24px'} height={'24px'} />
                          ),
                          onClick: () => {
                            setShowICPForm({ isOpen: true, edit: false, clone: true, id: data.id });
                          },
                        },
                        {
                          label: 'Delete',
                          labelSx: {
                            color: theme.palette.text.red,
                            fontSize: '16px',
                            fontWeight: '500',
                          },
                          icon: (
                            <img
                              src={images.icp.deleteIcon}
                              alt=""
                              width={'24px'}
                              height={'24px'}
                            />
                          ),
                          onClick: () => {
                            setIsOpenDeleteModal(true);
                          },
                        },
                      ]}
                    />
                  </Box>
                </CardActions>
              </Box>
            </Box>
            <CustomerCard>
              <BlackChip>
                <BlackChipText style={{ fontSize: '10px' }}>{data?.profileType}</BlackChipText>
              </BlackChip>
              <Typography
                onClick={() =>
                  setShowICPForm({ isOpen: true, edit: true, clone: false, id: data.id })
                }
                variant="h2"
                className={classes.cardLabelTypography}
                sx={{ cursor: 'pointer' }}
              >
                {data.name}
              </Typography>
            </CustomerCard>
          </Box>
          <ICPCardViewsGrid>{getUpdatedFieldList()}</ICPCardViewsGrid>
        </CardContent>
      </Card>
      <IcpModal
        open={isOpenModal}
        content={
          <IcpModalContent>
            {!data.status ? 'Activated ICPs can be used.' : 'Deactivated ICPs cannot be used.'}
            <br />
            Are you sure you want to proceed?
          </IcpModalContent>
        }
        secondButtonTitle={!data.status ? 'Activate' : 'Deactivate'}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        onSubmit={() => handleUpdateICP()}
      />
      <IcpModal
        isErrorButton={true}
        open={isOpenDeleteModal}
        content={
          <IcpModalContent>
            Deleting ICPs will permanently remove all data.
            <br />
            Are you sure you want to proceed?
          </IcpModalContent>
        }
        secondButtonTitle="Delete"
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onSubmit={() => handelDeleteICP()}
      />
    </>
  );
};

export default ICPCard;
