import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Form } from '../../../../../components/shared';
import images from '../../../../../config/images';
import { useStyles } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import * as Yup from 'yup';

const AudienceOptions = {
  CANDIDATE: 'Candidate',
  CUSTOMER: 'Customer',
};

const validationSchema = (isEmailForm) => {
  return Yup.object().shape({
    purpose: Yup.string().when(isEmailForm ? 'email_type' : 'type', {
      is: (type) => {
        return !isEmailForm;
      },
      then: Yup.string().required('Please select purpose'),
      otherwise: Yup.string(),
    }),
    tone: Yup.object().required('Please select a tone of voice.').nullable(),
  });
};

const EmailWriterModal = ({
  handleClose,
  aiPromptFormResponse,
  loadingPage,
  fetchVoiceData,
  generateAIContent,
  options,
  selectedValue,
  setSelectedValue,
  aiPromptData,
}) => {
  const classes = useStyles();
  const [showInstructions, setShowInstructions] = useState(false);

  const fieldOptionsKey = {
    [AudienceOptions.CANDIDATE]: 'Candidate',
    [AudienceOptions.CUSTOMER]: 'Client',
  };

  const getFilteredData = (label, audience) => {
    if (aiPromptFormResponse) {
      const fieldData = aiPromptFormResponse?.find((ele) => ele?.formName === 'General Email');
      const labelFilteredData = fieldData?.formFields?.find((ele) => ele?.fieldName === label);
      const children = labelFilteredData?.fieldChildren?.find(
        (item) => fieldOptionsKey[audience] === item?.optionValue,
      )?.fields;
      return children?.map((item) => ({ name: item, id: item })) || [];
    }
    return [];
  };

  return (
    <Box>
      <Form
        initialValues={{
          purpose: aiPromptData?.purpose || '',
          audience: aiPromptData?.audience || AudienceOptions.CANDIDATE,
          tone: { ...selectedValue },
          additionalInstructions: aiPromptData?.additionalInstructions || '',
        }}
        validationSchema={validationSchema}
        enableReinitialize={false}
        onSubmit={generateAIContent}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
            >
              <Box className={classes.modalHeader}>
                <img src={images.icons.magicAiIcon} alt="AI Icon" />
                <Box className={classes.modalTitleContainer}>
                  <Typography className={classes.modalTitle}>AI Email Writer</Typography>
                  <Typography className={classes.modalSubTitle}>
                    Give us some basic information and our AI will craft the perfect email for you!{' '}
                  </Typography>
                </Box>
                <CloseIcon onClick={handleClose} className={classes.closeButton} />
              </Box>

              <Divider />

              <Box className={classes.formContent}>
                <Box className={classes.formField}>
                  <Typography className={classes.fieldLabel}>Choose your audience:</Typography>
                  <Box display="flex" gap="16px">
                    <Button
                      onClick={() => {
                        if (values?.audience === AudienceOptions.CANDIDATE) return;
                        formProps.setFieldValue('audience', AudienceOptions.CANDIDATE);
                        formProps.setFieldValue('purpose', '');
                      }}
                      variant={values?.audience === AudienceOptions.CANDIDATE ? 'contained' : ''}
                      color={values?.audience === AudienceOptions.CANDIDATE ? 'gradient' : ''}
                      className={`${classes.actionButton} ${classes.grayButton}`}
                    >
                      {AudienceOptions.CANDIDATE}
                    </Button>
                    <Button
                      variant={values?.audience === AudienceOptions.CUSTOMER ? 'contained' : ''}
                      color={values?.audience === AudienceOptions.CUSTOMER ? 'gradient' : ''}
                      onClick={() => {
                        if (values?.audience === AudienceOptions.CUSTOMER) return;
                        formProps.setFieldValue('audience', AudienceOptions.CUSTOMER);
                        formProps.setFieldValue('purpose', '');
                      }}
                      className={`${classes.actionButton} ${classes.grayButton}`}
                    >
                      {AudienceOptions.CUSTOMER}
                    </Button>
                  </Box>
                </Box>

                <Box className={`${classes.formField}`}>
                  <Typography className={classes.fieldLabel}>
                    Confirm the purpose of your email:
                  </Typography>
                  <Box maxWidth="300px" width={'100%'}>
                    <Form.Field.Select
                      options={getFilteredData('email_purpose', values?.audience) || []}
                      placeholder={'Select purpose'}
                      fullWidth
                      size="small"
                      variant="outlined"
                      name={'purpose'}
                      optLabel="name"
                      className={classes.purpose}
                      isSmall
                      optValue="id"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            overflow: 'auto',
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box className={`${classes.formField}`}>
                  <Typography className={classes.fieldLabel}>Choose your tone of voice:</Typography>
                  <Box width="100%">
                    <Form.Field.AutoComplete
                      options={options || []}
                      fullWidth
                      isSmall
                      onChange={(val) => {
                        if (val) {
                          setSelectedValue(val);
                        }
                      }}
                      value={selectedValue}
                      className={classes.selector}
                      clearOnEscape={true}
                      name="tone"
                      placeholder="Select tone of voice"
                      optLabel="name"
                      optValue="id"
                      variant="outlined"
                      isAiWriter={true}
                      addNewLabel={'Create first voice'}
                      remoteMethod={(val) => fetchVoiceData(val)}
                      allowAdd={true}
                      clearIcon={<span onClick={() => fetchVoiceData('')}>&times;</span>}
                      loading={loadingPage}
                    />
                  </Box>
                </Box>

                <Box className={`${classes.formField} ${classes.selector}`}>
                  <Box display="flex" gap="8px">
                    <AddCircleOutlineRoundedIcon
                      onClick={() => setShowInstructions(true)}
                      color="secondary"
                      cursor="pointer"
                    />
                    <Box width="300px">
                      <Typography
                        onClick={() => setShowInstructions(true)}
                        className={classes.additionalInstructionsLabel}
                        color="secondary"
                      >
                        Add additional instructions (optional)
                      </Typography>
                      {showInstructions && (
                        <Typography paddingTop="8px" className={classes.additionalInstructionsText}>
                          Add any specific details you'd like included, such as key points, desired
                          length, or unique offers. This helps create a message that’s spot-on for
                          your needs.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {showInstructions && (
                    <Form.Field.Input
                      fullWidth
                      multiline
                      rows="5"
                      variant="outlined"
                      name="additionalInstructions"
                      size="small"
                      isSmall
                    />
                  )}
                </Box>
              </Box>

              <Divider />

              <Box className={classes.modalHeader} justifyContent="flex-end">
                <Button onClick={handleClose} variant="text" className={classes.cancelButton}>
                  Cancel
                </Button>
                <Button className={classes.generateButton} type="submit">
                  <img src={images.icons.magicIcon} alt="AI Icon" /> Generate
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </Box>
  );
};

export default EmailWriterModal;
