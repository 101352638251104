import * as appTypes from './appTypes';

export const searchPanelContacts = (search) => ({
  type: appTypes.SEARCH_PANEL_CONTACTS,
  search,
});

export const handlePanelBulkAddUpdate = (data, resolve, reject) => ({
  type: appTypes.PANEL_BULK_ADD_UPDATE,
  data,
  resolve,
  reject,
});

export const handleAddContactToPanel = (panelId, contact, resolve, reject) => ({
  type: appTypes.HANDLE_ADD_CONTACT_TO_PANEL,
  panelId,
  contact,
  resolve,
  reject,
});

export const fetchOnboardingStatus = () => ({ type: appTypes.FETCH_ONBOARDING_STATUS });
export const setOnboardingStatus = (data) => ({ type: appTypes.SET_ONBOARDING_STATUS, data });
export const putOnboardingStatus = (key, value) => ({
  type: appTypes.PUT_ONBOARDING_STATUS,
  key,
  value,
});

export const resetStore = () => ({ type: appTypes.RESET_STORE });

export const removeContactFromPanel = (panelId, contactId) => ({
  type: appTypes.REMOVE_CONTACT_FROM_PANEL,
  panelId,
  contactId,
});

export const fetchPanelContacts = (panelId) => ({
  type: appTypes.FETCH_PANEL_CONTACTS,
  panelId,
});

export const setPanelContacts = (panelId, data) => ({
  type: appTypes.SET_PANEL_CONTACTS,
  panelId,
  data,
});

export const handleDashboardDragDrop = (data) => ({
  type: appTypes.HANDLE_DASHBOARD_DRAG_DROP,
  data,
});

export const fetchDashboardContacts = (paging, filters, resolve, reject) => ({
  type: appTypes.FETCH_DASHBOARD_CONTACTS,
  paging,
  filters,
  resolve,
  reject,
});

export const setDashboardContacts = (contacts, total) => ({
  type: appTypes.SET_DASHBOARD_CONTACTS,
  contacts,
  total,
});

export const setActiveDashboard = (dashboardId) => ({
  type: appTypes.SET_ACTIVE_DASHBOARD,
  dashboardId,
});
export const postBoard = (payload, resolve, reject) => ({
  type: appTypes.POST_DASHBOARD,
  payload,
  resolve,
  reject,
});
export const putBoard = (boardId, payload, resolve, reject) => ({
  type: appTypes.PUT_DASHBOARD,
  boardId,
  payload,
  resolve,
  reject,
});
export const deleteBoard = (boardId, resolve, reject) => ({
  type: appTypes.DELETE_DASHBOARD,
  boardId,
  resolve,
  reject,
});

export const postPanel = (payload, resolve, reject) => ({
  type: appTypes.POST_PANEL,
  payload,
  resolve,
  reject,
});
export const putPanel = (panelId, payload, resolve, reject) => ({
  type: appTypes.PUT_PANEL,
  panelId,
  payload,
  resolve,
  reject,
});

export const deletePanel = (panelId, resolve, reject) => ({
  type: appTypes.DELETE_PANEL,
  panelId,
  resolve,
  reject,
});

export const fetchDashboards = () => ({ type: appTypes.FETCH_DASHBOARDS });

export const setDashboards = (data) => ({ type: appTypes.SET_DASHBOARDS, data });

export const fetchPanels = (dashboardId) => ({ type: appTypes.FETCH_PANELS, dashboardId });
export const setPanels = (data) => ({ type: appTypes.SET_PANELS, data });

export const fetchGlobals = () => ({ type: appTypes.FETCH_GLOBALS });
export const fetchTaskCount = () => ({ type: appTypes.FETCH_TASK_COUNTS });
export const setGlobals = (data) => ({ type: appTypes.SET_GLOBALS, data });
export const setTaskCounts = (data) => ({ type: appTypes.SET_TASK_COUNTS, data });

export const toggleSidebar = () => ({ type: appTypes.TOGGLE_SIDEBAR });

export const toggleContactDrawer = (contactId) => ({
  type: appTypes.TOGGLE_CONTACT_DRAWER,
  contactId,
});

export const setCurrentHeader = () => ({ type: appTypes.SET_HEADER });

export const setAppLoading = (loading) => ({
  type: appTypes.SET_APP_LOADING,
  loading,
});

export const setAppDataLoading = (loading) => ({
  type: appTypes.SET_APP_DATA_LOADING,
  loading,
});

export const openCloseSideBar = () => ({
  type: appTypes.TOGGLE_SIDEBAR,
});
export const toggleAddContactForm = (contact = {}) => ({
  type: appTypes.TOGGLE_CONTACT_FORM,
  contact,
});
export const toggleAddSequenceForm = () => ({
  type: appTypes.TOGGLE_SEQUENCE_FORM,
});
export const toggleNotificationsDrawer = () => ({
  type: appTypes.TOGGLE_NOTIFICATIONS_DRAWER,
});
export const toggleCreateEmailForm = (actionType) => ({
  type: appTypes.TOGGLE_EMAIL_FORM,
  actionType,
});
export const toggleAddTaskForm = (task = {}) => ({
  type: appTypes.TOGGLE_TASK_FORM,
  task,
});

export const setLoginStatus = (status) => ({
  type: appTypes.SET_LOGIN_STATUS,
  status,
});

export const fetchReplacementVariables = () => ({
  type: appTypes.FETCH_REPLACEMENT_VARIABLES,
});

export const setReplacementVariables = (data = []) => ({
  type: appTypes.SET_REPLACEMENT_VARIABLES,
  data,
});

export const fetchTenantConfig = () => ({
  type: appTypes.FETCH_TENANT_CONFIG,
});

export const fetchCRMConfig = (crmName) => ({
  type: appTypes.FETCH_CRM_FIELDS,
  crmName,
});

export const fetchCRMNotes = (crmName) => ({
  type: appTypes.FETCH_CRM_NOTES,
  crmName,
});

export const fetchSrNotes = (crmName) => ({
  type: appTypes.FETCH_SR_NOTES,
  crmName,
});

export const setTenantConfig = (data) => ({
  type: appTypes.SET_TENANT_CONFIG,
  data,
});

export const setAttributes = (attributes, modules, unassignedFields) => ({
  type: appTypes.SET_ATTRIBUTES,
  attributes,
  modules,
  unassignedFields,
});

export const setTenantFileds = (data) => ({
  type: appTypes.SET_TENANT_FIELDTYPES,
  data,
});

export const setCRMNotes = (notes) => ({
  type: appTypes.SET_CRM_NOTES,
  notes,
});

export const setSrNotes = (data) => ({
  type: appTypes.SET_SR_NOTES,
  data,
});

export const putAttribute = (attributeId, payload, resolve, reject) => ({
  type: appTypes.PUT_ATTRIBUTE,
  attributeId,
  payload,
  resolve,
  reject,
});

export const postAttribute = (payload, resolve, reject) => ({
  type: appTypes.POST_ATTRIBUTE,
  payload,
  resolve,
  reject,
});

export const deleteAttribute = (attributeId, resolve, reject) => ({
  type: appTypes.DELETE_ATTRIBUTE,
  attributeId,
  resolve,
  reject,
});

export const handleAttributeDragDrop = (module, data) => ({
  type: appTypes.HANDLE_ATTRIBUTE_DRAG_DROP,
  module,
  data,
});

export const fetchReportSummary = (filter) => ({
  type: appTypes.FETCH_REPORT_SUMMARY,
  filter,
});

export const setReportSummary = (data) => ({
  type: appTypes.SET_REPORT_SUMMARY,
  data,
});

export const fetchActivityReport = (paging, filters, sort) => ({
  type: appTypes.FETCH_ACTIVITY_REPORT,
  paging,
  filters,
  sort,
});

export const setActivityReport = (data, total) => ({
  type: appTypes.SET_ACTIVITY_REPORT,
  data,
  total,
});

export const saveTenantConfig = (data) => ({
  type: appTypes.SAVE_TENANT_CONFIG,
  data,
});

export const updateTenantConfig = ({ id, data }) => ({
  type: appTypes.UPDATE_TENANT_CONFIG,
  id,
  data,
});

export const fetchTenantConfigData = (data) => ({
  type: appTypes.FETCH_TENANT_DATA,
  data,
});

export const setTenantConfigData = (data) => ({
  type: appTypes.SET_TENANT_DATA,
  data,
});

export const RefreshSyncData = (data) => ({
  type: appTypes.REFRESH_SYNC_TENANT_DATA,
  data,
});

export const MappedNote = (id, notes) => ({
  type: appTypes.MAPPED_NOTE,
  id,
  notes,
});

export const AddNoteAction = (id, note) => ({
  type: appTypes.ADD_NOTE_ACTION,
  id,
  note,
});

export const fetchSeqDashboards = (data, resolve, reject) => ({
  type: appTypes.FETCH_SEQ_DASHBOARDS,
  data,
  resolve,
  reject,
});

export const fetchSeqDashboardsUser = (data, resolve, reject) => ({
  type: appTypes.FETCH_SEQ_DASHBOARDS_USER,
  data,
  resolve,
  reject,
});

export const fetchSeqDashboardsTeam = (data, resolve, reject) => ({
  type: appTypes.FETCH_SEQ_DASHBOARDS_TEAM,
  data,
  resolve,
  reject,
});

export const fetchSeqDashboardsReport = (data, resolve, reject) => ({
  type: appTypes.FETCH_SEQ_DASHBOARDS_REPORT,
  data,
  resolve,
  reject,
});

export const fetchActivityDashboards = (data, resolve, reject) => ({
  type: appTypes.FETCH_ACTIVITY_DASHBOARDS,
  data,
  resolve,
  reject,
});

export const fetchActivityDashboardsChart = (data, resolve, reject) => ({
  type: appTypes.FETCH_ACTIVITY_DASHBOARDS_CHART,
  data,
  resolve,
  reject,
});

export const setOutreachBoarding = (data) => ({
  type: appTypes.SET_OUTREACH_ONBOARDING,
  data,
});
