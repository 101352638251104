import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import SequenceNavBar from './SequenceNavBar';
import SequenceSteps from './SequenceSteps';
import SettingsForm from './SettingsForm';
import PublishSequence from './PublishSequence';
import { OnboardingContainer, OnboardingContent, OnboardingContentWrapper } from './styles';

import { getSequenceFields } from 'src/modules/app/actions/appSelector';
import {
  postSequenceEvent,
  putSequenceEvent,
  deleteSequenceEvent,
  handleEventsReorder,
  putSequence,
  postSequence,
} from '../../actions/sequenceActions';
import AIWriteForm from './AIwriterForm';
import useFeatureAccess from '../../../../components/hooks/useFeatureAccess';

const SequenceOnboarding = ({ sequence, onClose, sequenceEvents, fields, ...props }) => {
  const tenant = useSelector((state) => state.app.tenant);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saveAsDraftLoading, setSaveAsDraftLoading] = useState(false);
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const isAIWriter = searchParams.get('ai');

  useEffect(() => {
    if (params?.id) setActiveStep(1);
  }, [params]);

  useEffect(() => {
    if (activeStep > 3) {
      onClose();
    }
  }, [activeStep]);

  const publishTask = async () => {
    setLoading(true);
    await props.putSequence(sequence.id, {
      status: 'published',
    });
    setLoading(false);
    onClose();
  };

  const saveAsDraft = async () => {
    setSaveAsDraftLoading(true);
    await props.putSequence(sequence.id, {
      status: 'unpublished',
    });
    setSaveAsDraftLoading(false);
    onClose();
  };

  const moveToNext = () => {
    setActiveStep(activeStep + 1);
  };

  const onCreateSequence = (sequence, id, onErrorHandle) => {
    try {
      if (id) {
        return props.putSequence(id, sequence, setActiveStep);
      } else {
        return props.postSequence({ ...sequence, stopRedirect: true });
      }
    } catch (e) {
      onErrorHandle();
    }
  };
  const hasAIWriterAccess = useFeatureAccess('generateSequenceWithAI');
  const isAIWriterActive = hasAIWriterAccess ? isAIWriter : false;

  return (
    <OnboardingContainer>
      <SequenceNavBar
        onClose={onClose}
        sequence={sequence}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        publishTask={publishTask}
        saveAsDraft={saveAsDraft}
        loading={loading}
        isAIWriter={isAIWriterActive}
        saveAsDraftLoading={saveAsDraftLoading}
      />
      <OnboardingContentWrapper>
        <OnboardingContent maxWidth="md">
          {activeStep == 0 ? (
            <>
              <AIWriteForm
                isAIWriter={isAIWriterActive}
                loading={props?.sequenceAddEdit}
                sequenceAccessTypeList={props.sequenceAccessTypeList}
                sequenceEmailTypeList={props.sequenceEmailTypeList}
                user={props.user}
                sequence={sequence}
                onClose={onClose}
                onSubmit={onCreateSequence}
              />
            </>
          ) : null}

          {activeStep == 1 ? (
            <SequenceSteps sequence={sequence} sequenceEvents={sequenceEvents} new {...props} />
          ) : null}

          {activeStep == 2 ? (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <SettingsForm
                  sequence={sequence}
                  sequenceEvents={sequenceEvents}
                  fields={fields}
                  moveToNext={moveToNext}
                  isOnboarding={true}
                  {...props}
                />
              </Grid>
            </Grid>
          ) : null}

          {activeStep == 3 ? (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={8}>
                <PublishSequence
                  steps={sequenceEvents.length}
                  events={sequenceEvents}
                  loading={loading}
                  days={0}
                  onPublish={publishTask}
                />
              </Grid>
            </Grid>
          ) : null}
        </OnboardingContent>
      </OnboardingContentWrapper>
    </OnboardingContainer>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.admin.admin.integrations,
  loading: state.sequence.loading.sequence,
  sequenceAddEdit: state.sequence.loading.sequenceAddEdit,
  sequence: state.sequence.sequence,
  sequenceEvents: state.sequence.sequenceEvents,
  fields: getSequenceFields(state),
  sequenceAccessTypeList: state.app.globals.sequenceAccessTypeList,
  sequenceEmailTypeList: state.app.globals.sequenceEmailTypeList,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  putSequence: (sequenceId, payload, setActiveStep) => {
    return new Promise((resolve, reject) => {
      dispatch(putSequence(sequenceId, payload, resolve, reject, setActiveStep));
    });
  },
  postSequenceEvent: (sequenceId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postSequenceEvent(sequenceId, payload, resolve, reject));
    });
  },
  putSequenceEvent: (sequenceId, stepId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putSequenceEvent(sequenceId, stepId, payload, resolve, reject));
    });
  },
  deleteSequenceEvent: (sequenceId, stepId, hideToaster) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteSequenceEvent(sequenceId, stepId, resolve, reject, hideToaster));
    });
  },
  postSequence: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(postSequence(data, resolve, reject));
    });
  },
  handleEventsReorder: (sequenceId, stepId, eventOrder) =>
    dispatch(handleEventsReorder(sequenceId, stepId, eventOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SequenceOnboarding);
