import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import LoginDashboardView from './LoginDashboardView';
// import LandingPageView from "./LandingPageView";
import LandingPageView from './LandingPageView';
import { fetchIcp, fetchVoices } from '../../../auth/actions/authActions';

export const WelcomePage = (props) => {
  useEffect(() => {
    if (props.icp?.length < 0) {
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
    }
    if (!props.voices?.length < 0) {
      props.fetchVoices(props.voices.paging, props.voices.filters, props.voices.sort);
    }
  }, []);
  return (
    <React.Fragment>
      <PageTitle title="Landing Page" />
      {/*<LandingPageView {...props} />*/}
      <LoginDashboardView {...props} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.user,
  newUser: state.auth.newUser,
  icp: state.auth?.icp,
  voices: state.auth?.voices,
});

const mapDispatchToProps = (dispatch) => ({
  fetchICP: (paging, filters, sort) => dispatch(fetchIcp(paging, filters, sort)),
  fetchVoices: (paging, filters, sort) => dispatch(fetchVoices(paging, filters, sort)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
