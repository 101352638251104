import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Paper, Popover } from '@mui/material';
import { Box, Typography, ButtonBase } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const CardContainer = styled(Paper).attrs({
  elevation: 2,
})`
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px #e0e0e0;
  margin-left: 1px;
  border: ${(props) => (props?.anchorEl ? '1px solid #1976D2' : 'none')};
  background: ${(props) =>
    props?.anchorEl
      ? 'linear-gradient(0deg, rgba(25, 118, 210, 0.08) 0%, rgba(25, 118, 210, 0.08) 100%), #FFF'
      : '#FFF'};
  cursor: ${(props) => (props?.showCursorPointer ? 'pointer' : 'default')};
`;

export const CardHeader = styled(Box).attrs({})`
  display: flex;
  padding: 8px 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.renderborder === 'true' ? '1px solid rgba(0, 0, 0, 0.12)' : 'none'};
`;

export const CardContent = styled(Box).attrs({})`
  padding: 16px 20px;
`;

export const Left = styled(Box).attrs({})`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px 0px;
`;

export const Right = styled(Box).attrs({})`
  display: flex;
  align-items: center;
`;

export const PopoverCard = styled(Paper)`
  display: flex;
  width: 500px;
  flex-direction: column;
  pointer-events: auto;
  gap: 16px;
  padding: 24px;
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14),
    0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow: auto;
`;

export const PopoverTitle = styled(Typography)`
  color: #00000061;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
`;

export const PopoverContent = styled.div`
  color: #000000de;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const iconButtonStyle = {
  // color: 'rgba(0, 0, 0, 0.26)',
  // ':hover': {
  //   color: '#031729',
  // },
  opacity: 0.6,
};

const showSubject = ['linkedinMail', 'email'];

export const EventCard = ({
  icon,
  title,
  subTitle,
  showEdit,
  showDelete,
  customAction,
  showHover = true,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
  });

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const isNearBottom = rect.bottom > window.innerHeight - 300;
      setPopoverPosition({
        anchorOrigin: isNearBottom
          ? { vertical: 'top', horizontal: 'center' }
          : { vertical: 'bottom', horizontal: 'center' },
        transformOrigin: isNearBottom
          ? { vertical: 'bottom', horizontal: 'left' }
          : { vertical: 'top', horizontal: 'left' },
      });
    }
  }, [anchorEl]);

  return (
    <>
      <CardContainer
        aria-owns={Boolean(anchorEl) ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event) => {
          if (props?.event?.templates?.id || props?.event?.description) {
            setAnchorEl(event?.currentTarget);
          }
        }}
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
        showCursorPointer={props?.event?.templates?.id || props?.event?.description}
        anchorEl={Boolean(anchorEl)}
      >
        <CardHeader renderborder={props.children ? 'true' : 'false'}>
          <Left>
            {icon && (
              <>
                {icon}
                <Spacer x={1} />
              </>
            )}
            <Box display="flex">
              <Typography
                align={!icon ? 'center' : 'left'}
                variant="h4"
                color="textPrimary"
                style={{ flex: 1 }}
              >
                {title}
              </Typography>
              {subTitle && subTitle !== '' ? (
                <>
                  <Spacer x={1} y={1} />
                  <Typography variant="h4" color="textSecondary" variant="body2">
                    {subTitle}
                  </Typography>
                </>
              ) : null}
            </Box>
          </Left>
          {customAction || showEdit || showDelete ? (
            <Right>
              {customAction ? customAction() : null}
              {showEdit && (
                <Button iconButton onClick={props.onEdit} color="primary">
                  <EditIcon style={iconButtonStyle} />
                </Button>
              )}

              {showDelete && (
                <Button iconButton onClick={props.onDelete} color="primary">
                  <DeleteIcon style={iconButtonStyle} />
                </Button>
              )}
            </Right>
          ) : null}
        </CardHeader>
        {props.children && <CardContent>{props.children}</CardContent>}
      </CardContainer>
      {showHover ? (
        <Popover
          disableRestoreFocus
          id="mouse-over-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          disableScrollLock
          sx={{
            pointerEvents: 'none',
            '& .MuiPaper-root': {
              borderRadius: '13px',
            },
          }}
          slotProps={{
            paper: {
              onMouseEnter: () => {
                setAnchorEl(anchorEl); // Keep popover open
              },
              onMouseLeave: () => {
                setAnchorEl(null); // Close popover
              },
            },
          }}
          anchorOrigin={popoverPosition.anchorOrigin}
          transformOrigin={popoverPosition.transformOrigin}
        >
          <PopoverCard>
            {showSubject.includes(props?.event?.eventType) ? (
              <Box>
                <PopoverTitle>Subject</PopoverTitle>
                <PopoverContent
                  dangerouslySetInnerHTML={{
                    __html:
                      props?.event?.templates?.subject ||
                      props?.event?.subject ||
                      props?.event?.templateProps?.subject ||
                      '-',
                  }}
                />
              </Box>
            ) : null}
            <Box>
              <PopoverTitle>Content</PopoverTitle>
              <PopoverContent
                dangerouslySetInnerHTML={{
                  __html:
                    props?.event?.templates?.content ||
                    props?.event?.description ||
                    props?.event?.templateProps?.body ||
                    '-',
                }}
              />
            </Box>
          </PopoverCard>
        </Popover>
      ) : null}
    </>
  );
};
EventCard.propTypes = {
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  fullWidth: PropTypes.bool,
  customAction: PropTypes.func,
};
EventCard.defaultProps = {
  showEdit: false,
  showDelete: false,
  onEdit: () => {},
  onDelete: () => {},
  fullWidth: true,
  customAction: () => {},
};
