import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  noSidebar: {
    margin: '0 auto',
    height: '50px',
    maxWidth: '1536px',
    boxShadow: 'none',
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    left: 0,
    right: 0,
    background: '#fafafa',
  },
  root: {
    margin: 0,
    height: '50px',
    left: '230px',
    width: 'calc(100% - 230px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootBoards: {
    margin: 0,
    height: '50px',
    left: '230px',
    width: 'calc(100% - 230px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootDashboard: {
    margin: 0,
    height: '50px',
    left: '300px',
    width: 'calc(100% - 300px)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  rootCollapsed: {
    left: '64px',
    width: 'calc(100% - 64px)',
  },
  searchPopper: {
    width: '400px !important',
    marginLeft: '100px',
    overflowX: 'hidden',
  },
  toolbarStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 12px 0 6px',
  },
  userButton: {
    // background: 'rgba(0, 0, 0, 0.06)',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '5px 12px',
  },
  drawer: {
    background: '#000',
    width: '150%',
    padding: '5px 12px',
  },
  iconStyle: {
    padding: '12px 0',
    display: 'grid',
    justifyContent: 'center',
    gap: '12px',
    width: '100px',
    height: '94px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    color: (props) => (props.isLandingPage ? theme.palette.text.white : theme.palette.text.primary),
    '&:hover': {
      backgroundColor: (props) =>
        props.isLandingPage
          ? theme.palette.background.darkBlue
          : theme.palette.background.lightWhite,
    },
  },
  menuAvtar: {
    width: '20px',
    height: '20px',
    fontSize: '16px',
    backgroundColor: '#AB47BC',
    textTransform: 'capitalize',
  },
  exploreWrapper: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    backgroundColor: (props) =>
      props.isLandingPage ? "#1A2633" : theme.palette.background.paper,

    gap: '8px',
  },
  exploreTitle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    color: (props) =>
      props.isLandingPage ? "#CCCCCC" : theme.palette.text.primary,
  },
  personaliseContentTitle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: (props) => (props.isLandingPage ? theme.palette.text.white : theme.palette.text.primary),
  },
  personaliseContentTitleNumber: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: (props) =>
      props.isLandingPage ? "#FFD480" : theme.palette.text.primary,
  },
  menuAvatar: {
    width: '60px',
    height: '60px',
    fontSize: '32px',
    backgroundColor: '#AB47BC',
    textTransform: 'capitalize',
  },
  freeTrialButton: {
    color: theme.palette.text.white,
    padding: '2px 8px',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '14px',
    letterSpacing: '0.46px',
    background: 'linear-gradient(236deg, #FFD480 0.92%, #F240A4 37.89%, #1976D2 98.63%)',
    boxShadow: '0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.15), 0px 1px 0.75px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: '9px',
    height: 'fit-content',
  },
  menuPaper: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    backgroundColor: (props) =>
      props.isLandingPage ? '#131C26' : theme.palette.background.default,
    borderRadius: '16px',
    marginTop: '10px',
    marginLeft: '-13px',
  },
  goToProfile: {
    paddingLeft: '0px',
    fontSize: '12px',
    color: theme.palette.secondary.main,
  },
  menuTypography: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '28px',
    color: (props) => (props.isLandingPage ? theme.palette.text.white : theme.palette.text.primary),
  },
  menuEmailWrapper: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    color: (props) => (props.isLandingPage ? theme.palette.text.white : theme.palette.text.primary),
  },
}));

export default useStyles;
