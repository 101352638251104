import { ReactComponent as EmptyIcon } from 'src/assets/lms/icons/empty.svg';
import { ReactComponent as CheckmarkIcon } from 'src/assets/lms/icons/checkmark.svg';
import { ReactComponent as SortIcon } from 'src/assets/lms/icons/sort.svg';
import { ReactComponent as TagIcon } from 'src/assets/lms/icons/tag.svg';
import { ReactComponent as SavedIcon } from 'src/assets/lms/icons/saved.svg';
import { ReactComponent as HelpIcon } from 'src/assets/lms/icons/help.svg';
import { ReactComponent as OutreachIcon } from 'src/assets/lms/icons/outreach.svg';
import { ReactComponent as LockIcon } from 'src/assets/lms/icons/lock.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/lms/icons/linkedin.svg';
import { ReactComponent as FlagIcon } from 'src/assets/lms/icons/flag.svg';
import { ReactComponent as LayersIcon } from 'src/assets/lms/icons/layers.svg';
import { ReactComponent as Award } from 'src/assets/lms/icons/award.svg';
import { ReactComponent as TeamIcon } from 'src/assets/lms/icons/team.svg';
import { ReactComponent as ActivityIcon } from 'src/assets/lms/icons/activity.svg';
import { ReactComponent as RoleplayIcon } from 'src/assets/lms/icons/roleplay.svg';
import { ReactComponent as ScoreIcon } from 'src/assets/lms/icons/score.svg';
import { ReactComponent as VideosIcon } from 'src/assets/lms/icons/videos.svg';
import { ReactComponent as PercentIcon } from 'src/assets/lms/icons/percent.svg';
import { ReactComponent as SessionPlaceholder } from 'src/assets/lms/icons/SessionPlaceholder.svg';
import { ReactComponent as TickIcon } from 'src/assets/lms/icons/tick.svg';
import { ReactComponent as AssignThisIcon } from 'src/assets/images/LMS/assignThisIcon.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/lms/icons/error.svg';
import { ReactComponent as CardsIcon } from 'src/assets/lms/icons/cards.svg';
import { ReactComponent as UserIcon } from 'src/assets/lms/icons/user.svg';
import { ReactComponent as CodeIcon } from 'src/assets/lms/icons/code.svg';
import { ReactComponent as RebaseIcon } from 'src/assets/lms/icons/rebase.svg';
import { ReactComponent as ItemPlayIcon } from 'src/assets/lms/icons/itemplay.svg';
import { ReactComponent as TrillionIcon } from 'src/assets/lms/icons/trillion.svg';
import { ReactComponent as RocketIcon } from 'src/assets/lms/icons/rocket.svg';

const iconConfig = {
  empty: EmptyIcon,
  check: CheckmarkIcon,
  sort: SortIcon,
  tags: TagIcon,
  saved: SavedIcon,
  outreach: OutreachIcon,
  help: HelpIcon,
  lock: LockIcon,
  flag: FlagIcon,
  layers: LayersIcon,
  competition: Award,
  team: TeamIcon,
  activity: ActivityIcon,
  roleplay: RoleplayIcon,
  score: ScoreIcon,
  videos: VideosIcon,
  percent: PercentIcon,
  sessionPlaceholder: SessionPlaceholder,
  tick: TickIcon,
  assign: AssignThisIcon,
  error: ErrorIcon,
  cards: CardsIcon,
  user: UserIcon,
  linkedin: LinkedInIcon,
  code: CodeIcon,
  rebase: RebaseIcon,
  itemplay: ItemPlayIcon,
  trillion: TrillionIcon,
  rocket: RocketIcon,
};

export default iconConfig;
