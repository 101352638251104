'use client';
import React from 'react';
import WelcomeModal from './WelcomeModal';
import FeatureGuideModal from './FeatureGuide';
import ChromeExtensionBanner from './ChromeExtensionBanner';
import LMSPlatformModal from './FeatureGuide/LmsModal';
import InvitedUserModal from './FeatureGuide/InvitedUserModal';

const OutreachPageLayout = ({ sidebarWidth }) => {
  // Disable outreach onboarding for prod release. Will be released when qa is done.
  return null;
  return (
    <>
      <ChromeExtensionBanner />
      <FeatureGuideModal />
      <WelcomeModal />
      <LMSPlatformModal />
      <InvitedUserModal />
    </>
  );
};

export default OutreachPageLayout;
