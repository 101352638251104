import { useSelector } from 'react-redux';

export default function useFeatureAccess(type) {
  const { featureAccess = {} } = useSelector((state) => state.app.tenant);
  return (
    (featureAccess &&
    Object.keys(featureAccess).length > 0 &&
    featureAccess.hasOwnProperty(type) &&
    featureAccess[type] === true) || false
  );
}
