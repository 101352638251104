/* eslint-disable max-len */
import styled from '@emotion/styled';
import { Modal } from 'src/components/shared';

export const CustomModal = styled(Modal)(({ theme, ...props }) => ({
  zIndex: 999,
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    width: props.isHorizontal ? '960px' : '680px',
    overflow: 'hidden !important',
    maxWidth: 'unset',

    [theme.breakpoints.down('md')]: {
      margin: 24,
    },
  },

  '& .MuiDialogTitle-root': {
    display: 'none',
  },

  '& .MuiDialogContent-root': {
    padding: '0px !important',

    '& .content-container': {
      '.close-btn': {
        position: 'absolute',
        padding: '0px !important',
        top: '24px',
        right: '24px',
        zIndex: '3',
      },

      '.header-bg': {
        width: props.isHorizontal ? 'auto' : '100%',
        height: props.isHorizontal ? '100%' : 'auto',
        position: 'absolute',
        zIndex: '1',
      },

      '.header-container': {
        minHeight: '200px',
        position: 'relative',
        zIndex: '2',
        justifyContent: props.isHorizontal ? 'center' : 'flex-end',
        padding: props.isHorizontal ? '20px' : '32px 54px 32px',
        background:
          'linear-gradient(233deg, rgba(255, 212, 128, 0.30) 6.49%, rgba(242, 64, 164, 0.30) 38.69%, rgba(25, 118, 210, 0.30) 80.84%)',
        ...(props.isHorizontal ? { width: '44%' } : {}),

        [theme.breakpoints.down('md')]: {
          padding: 24,
          minHeight: '140px',
        },
      },

      '.main-container': {
        padding: props.isHorizontal ? '48px' : '24px 80px 40px',
        gap: '24px',
        zIndex: '10',
        background: theme.palette.background.paper,
        ...(props.isHorizontal ? { width: '56%' } : {}),

        [theme.breakpoints.down('md')]: {
          padding: 24,
        },

        '.footer-container': {
          marginTop: '16px',
          alignItems: 'center',
          zIndex: '10',
        },
      },
    },
  },

  '& .MuiDialog-paperWidthSm': {
    width: props.isHorizontal ? '640px' : '500px',

    '.main-container': {
      padding: props.isHorizontal ? '24px !important' : '24px 32px 40px !important',
    },
  },

  '& .MuiDialogActions-root': {
    display: 'none',
  },
}));
