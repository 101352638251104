import { Card, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FilterBar, PageTitle, Tabs } from 'src/components/App';
import { Button, Form, Spacer } from 'src/components/shared';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDataProviders, withBillingStore } from '../../utils/billingStore';
import { useDispatch, useSelector } from 'react-redux';
import api from 'src/api';
import { getRelativeDateRange } from 'src/utils/dateUtils';
import { convertObjectToQuerystring } from 'src/utils/helper';
import { updateTenant } from 'src/modules/app/api/appApis';
import { makeStyles } from '@mui/styles';
import { OpenInNew } from '@mui/icons-material';
import CreditRecharge from '../../components/Billing/CreditRecharge';
import toast from 'src/utils/toast';

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_ID,
  isItemsModel: true,
});

const filtersArr = [
  {
    key: 'createdAt',
    title: 'Filter By Date',
    custom: true,
    type: 'relativeDateRange',
    options: [
      { id: 'today', name: 'Today' },
      { id: 'yesterday', name: 'Yesterday' },
      { id: 'thisWeek', name: 'This week' },
      { id: 'lastWeek', name: 'Last week' },
      { id: 'thisMonth', name: 'This month' },
      { id: 'lastMonth', name: 'Last month' },
      { id: 'last3Month', name: 'Last 3 months' },
    ],
    isPrimary: true,
  },
];

const useStyles = makeStyles(() => ({
  noteTypeTooltip: {
    maxWidth: 'none',

    '& .tooltipWrapper': {
      background: '#082037',
      margin: -10,
      padding: 20,
      gap: 20,
      borderRadius: 6,
      maxWidth: 700,
    },
    '& .MuiTooltip-arrow': {
      color: '#082037',
      fontSize: 36,
    },
  },
}));

export const RoleplayMinutesView = (props) => {
  const tenant = useSelector((state) => state.app.tenant);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filters, setFilters] = useState({ createdAt: filtersArr[0].options[5] });
  const [userCount, setUserCount] = useState(0);
  const [loading, setLoading] = useState(undefined);
  const [roleplayCompleted, setRoleplayCompleted] = useState(0);
  const [showCreditRecharge, setShowCreditRecharge] = useState(false);
  const [roleplayLimitValues, setRoleplayLimitValues] = useState({
    roleplayAccountLimit: tenant?.roleplayAccountLimit ?? 0,
    roleplayUserLimit: tenant?.roleplayUserLimit ?? 0,
  });
  const { roleplayData, getRolePlayData, getHostedPage } = useDataProviders();

  useEffect(() => {
    const fetchData = async () => {
      getRolePlayData();
      const { total: { value } = { value: 0 } } =
        (await api(`/user?_from=0&_size=1`, null, 'get')) ?? {};
      const res = (await api(`roleplay/findroleplayminuteusage`, null, 'get')) ?? {};
      setRoleplayCompleted(res);
      setUserCount(value);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setRoleplayLimitValues({
      roleplayAccountLimit: tenant?.roleplayAccountLimit ?? 0,
      roleplayUserLimit: tenant?.roleplayUserLimit ?? 0,
    });
  }, [tenant]);

  useEffect(() => {
    const fetchData = async () => {
      const { start, end } = getRelativeDateRange(filters?.createdAt ?? {});
      const filter = filters?.createdAt
        ? convertObjectToQuerystring({ createdAt_gte: start, createdAt_lte: end })
        : null;
      const res = await api(
        `roleplay/findroleplayminuteusage${filter ? '?' + filter : ''}`,
        null,
        'get',
      );
      setRoleplayCompleted(res);
    };
    fetchData();
  }, [filters]);

  const onSubmitLimitValue = async () => {
    await updateTenant(tenant.id, {
      roleplayAccountLimit: Number(roleplayLimitValues?.roleplayAccountLimit ?? 0),
      roleplayUserLimit: Number(roleplayLimitValues?.roleplayUserLimit ?? 0),
    });
  };

  const { creditsPurchased: { roleplay = {} } = {} } = roleplayData ?? {};

  const initiatePayment = async (planId, subscription, isCharge) => {
    try {
      setShowCreditRecharge(undefined);
      cbInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            setLoading(planId);
            const quantity = subscription?.quantity || 0;
            getHostedPage(planId, subscription, isCharge, quantity)
              .then((res) => {
                setLoading(undefined);
                resolve(res);
              })
              .catch((err) => {
                setLoading(undefined);
                toast.error(err?.error?.message || 'An error ocurred! Please try again.');
              });
          });
        },
        close: function () {
          window.location.reload();
        },
      });
    } catch (error) {
      toast.error(error?.message || 'An error ocurred! Please try again.');
    }
  };

  const handleFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setFilters({
        [key]: value,
      });
    } else {
      const newFilter = JSON.parse(JSON.stringify(filters));
      delete newFilter[key];
      setFilters(newFilter);
    }
  };

  return (
    <Stack>
      <PageTitle title={'AI Coach Minutes'} />
      <Tabs
        sx={{
          '& .MuiTab-root': {
            fontSize: '18px',
            fontWeight: '500',
            padding: '10px 8px',
          },
        }}
        tabs={[
          {
            id: 'summary',
            name: 'AI Coaching Summary',
          },
        ]}
        showNewTabButton
        startIcon={null}
        value={'summary'}
      />
      <CreditRecharge
        tenant={tenant}
        subscriptions={roleplayData}
        open={showCreditRecharge}
        creditRechargeType={'roleplay'}
        onClose={() => setShowCreditRecharge(false)}
        initiatePayment={initiatePayment}
      />
      <Spacer y={2} />
      <Stack height={'100%'}>
        <FilterBar filters={filtersArr} appliedFilters={filters} onChange={handleFilterChange} />
        <Spacer y={2} />
        <div>
          <Stack className={'w-100'} gap="20px" direction={'row'}>
            {[
              {
                label: 'Balance Remaining',
                value: Math.round(tenant?.roleplayMinutes ?? 0),
                subtext: null,
              },
              {
                label: 'Total Charges',
                value: Math.round(roleplayCompleted.totalMinutes ?? 0),
                subtext: null,
              },
              {
                label: 'Last Purchase',
                value: `$${Math.round(roleplay?.price ?? 0)}`,
                subtext: roleplay?.purchasedOn
                  ? new Intl.DateTimeFormat('en-GB', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                    }).format(new Date(roleplay.purchasedOn))
                  : '',
              },
              {
                label: 'Coaching Sessions Completed',
                value: roleplayCompleted?.count ?? 0,
                subtext: null,
              },
            ].map((card, index) => (
              <Card
                key={index}
                sx={{
                  maxWidth: '228px',
                  width: '100%',
                  aspectRatio: '1/1',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '140px',
                  height: '100%',
                  borderRadius: '4px',
                  border: '1.035px solid rgba(0, 0, 0, 0.12)',
                  background: '#FFF',
                  boxShadow: 'none',
                }}
              >
                <Stack gap="4px" alignItems="center">
                  <Typography color="#00000099" fontSize="14px" fontWeight={500} textAlign="center">
                    {card.label}
                  </Typography>
                  {card.subtext && (
                    <Typography
                      color="#00000061"
                      fontSize="12px"
                      fontWeight={500}
                      lineHeight="16px"
                    >
                      {card.subtext}
                    </Typography>
                  )}
                </Stack>
                <Stack flex={1} alignItems="center" justifyContent="center">
                  <Typography color="#000000DE" fontSize="26px" fontWeight={600}>
                    {card.value}
                  </Typography>
                </Stack>
              </Card>
            ))}
          </Stack>
        </div>
      </Stack>
      <Spacer y={7} />

      <Tabs
        sx={{
          '& .MuiTab-root': {
            fontSize: '18px',
            fontWeight: 500,
            padding: '10px 8px',
          },
        }}
        tabs={[
          {
            id: 'management',
            name: 'AI Coach Pay-As-You-Go',
          },
        ]}
        value={'management'}
      />

      <Spacer y={2} />

      <Stack>
        <Typography color="#00000099">Control your spend each month</Typography>
        <Spacer y={2} />
        <Stack direction="row" gap={'24px'}>
          <Card
            sx={{
              width: 'calc(50% - 16px)',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'8px'}>
              <Typography color="#000000DE" fontWeight={500}>
                Account limits
              </Typography>
              <Typography color="#000000DE">
                Set a monthly spend for AI Coaching sessions.{' '}
              </Typography>
              <Form
                initialValues={{
                  userLimit: '',
                }}
                enableReinitialize={true}
                onSubmit={() => {}}
              >
                <Form.Field.Input
                  sx={{ maxWidth: '250px' }}
                  value={roleplayLimitValues.roleplayAccountLimit}
                  onChange={(roleplayAccountLimit) =>
                    setRoleplayLimitValues((prevValue) => {
                      return {
                        ...prevValue,
                        roleplayAccountLimit,
                      };
                    })
                  }
                  variant="outlined"
                />
              </Form>
            </Stack>
          </Card>
          <Card
            sx={{
              width: 'calc(50% - 16px)',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gap={'8px'}>
              <Stack direction="row" justifyContent={'space-between'}>
                <Typography color="#000000DE" fontWeight={500}>
                  User limits
                </Typography>
                <Typography color="#1976D2" fontWeight={500}>
                  {userCount} users
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap={'6px'}>
                <Typography color="#000000DE">
                  Set a monthly spend for each user.
                </Typography>
                <Tooltip
                  arrow
                  describeChild
                  tooltip
                  classes={{ tooltip: classes.noteTypeTooltip }}
                  title={
                    <Stack className="tooltipWrapper">
                      <Typography
                        style={{
                          whiteSpace: 'pre-line',
                          fontSize: 14,
                          color: 'white',
                        }}
                      >
                        {`We’ve gone ahead and created a default allocation for your users (your total 
                        minute allowance divided evenly across all of your active users).

                        Remember, you will never be charged more than your Account limit.`}
                      </Typography>
                    </Stack>
                  }
                >
                  <InfoOutlinedIcon style={{ fill: 'rgba(0, 0, 0, 0.26)', width: 22 }} />
                </Tooltip>
              </Stack>
              <Form
                initialValues={{
                  userLimit: '',
                }}
                enableReinitialize={true}
                onSubmit={() => {}}
              >
                <Form.Field.Input
                  sx={{ maxWidth: '250px' }}
                  value={roleplayLimitValues.roleplayUserLimit}
                  onChange={(roleplayUserLimit) =>
                    setRoleplayLimitValues((prevValue) => {
                      return {
                        ...prevValue,
                        roleplayUserLimit,
                      };
                    })
                  }
                  variant="outlined"
                />
              </Form>
            </Stack>
          </Card>
        </Stack>
        <Spacer y={2} />
        <Stack justifyContent={'flex-end'} direction="row">
          <Button
            sx={{ width: 'fit-content' }}
            variant="contained"
            color="secondary"
            onClick={onSubmitLimitValue}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default withBillingStore(RoleplayMinutesView);
