import React, { useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import VoicesGrid from './VoicesGrid';
import EmptyVoice from './EmptyVoice';
import AnalyseCopyModal from './VoiceDialog/AnalyseCopyModal';
import VoiceCreation from '../../container/createBrandVoice/VoiceCreation';
import { toggleVisibilityOfProfileTab } from '../../actions/authActions';
import { initialStateVoiceData } from './utils';
import { Form } from '../../../../components/shared';
import { keysToRemove } from '../../container/createBrandVoice/utils';
import { useSocketStore } from '../../../../components/hooks/socketConnector';
import toast from '../../../../utils/toast';
import { deepParse, isJson } from '../../../../utils/helper';

const initialState = {
  open: false,
  edit: null,
  creationType: null,
  isPromptView: false,
};

function VoicesView({ voicesTabs, voices, total, userBulkUpdate, ...props }) {
  const dispatch = useDispatch();
  const { emit, socket } = useSocketStore();
  const [openAICreateVoice, setOpenAICreateVoice] = useState(initialState);
  const [analyseCopyPrompts, setAnalyseCopyPrompt] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingForAI, setShowLoadingForAI] = useState(false);
  const [isDisabled, setIsDisable] = useState(false);
  const [voiceData, setVoiceData] = useState(initialStateVoiceData);
  const [finalSubmitLoading, setFinalSubmitLoading] = useState(false);
  const [showVoiceNameField, setShowVoiceNameField] = useState(false);

  const fetchVoiceDataById = async (editData) => {
    setShowLoading(true);
    setShowLoadingForAI(true);
    try {
      const existingVoice = await props.getVoiceById(editData?.id);
      const { status, voicePrompt, sampleVoice, creationType = 'manual' } = existingVoice.voice;
      setIsDisable(status === 'inactive');
      const keyMapping = {
        Headline: 'tone_of_voice',
        Detailed_Profile: 'characteristics',
        Style: 'style',
        Tone: 'tone',
      };
      const parsedVoicePrompt = voicePrompt
        ? isJson(voicePrompt)
          ? Object.entries(JSON.parse(voicePrompt)).reduce((acc, [key, value]) => {
              const normalizedKey = keyMapping[key] || key;
              acc[normalizedKey] = value;
              return acc;
            }, {})
          : { tone_of_voice: voicePrompt }
        : '';

      if (creationType === 'ai' && sampleVoice?.voice) {
        const bodyKey = Object.keys(sampleVoice.voice).find((key) => key.toLowerCase() === 'body');
        if (bodyKey) {
          parsedVoicePrompt.example = sampleVoice.voice[bodyKey];
        }
      }

      setVoiceData({
        ...existingVoice.voice,
        voicePrompt: parsedVoicePrompt,
        sampleVoice,
      });
    } catch (error) {
    } finally {
      setShowLoadingForAI(false);
      setShowLoading(false);
    }
  };

  const closeOpenView = () => {
    setAnalyseCopyPrompt('');
    dispatch(toggleVisibilityOfProfileTab(false));
    setOpenAICreateVoice(initialState);
    setVoiceData(initialStateVoiceData);
  };

  const getInitialValues = useCallback(() => {
    return {
      name: voiceData.name,
      isDefault: voiceData.isDefault,
      sampleVoice: voiceData?.sampleVoice || {},
      style: voiceData.params.style,
      tone: voiceData.params.tone,
      sampleText: voiceData?.sampleText || '',
      voicePrompt: voiceData?.voicePrompt || {},
    };
  }, [voiceData]);

  const getFormValidation = () => {
    const baseValidation = {
      name: Yup.string().required('Name is required'),
      style: Yup.string().required('Style is required'),
    };

    if (openAICreateVoice?.creationType === 'ai') {
      return {
        ...baseValidation,
        sampleText: Yup.string().required(
          'Please provide a sample text to generate your tone of voice.',
        ),
      };
    }

    return baseValidation;
  };

  const handleSubmit = async (values) => {
    const formVoiceData = {
      name: values?.name,
      isDefault: values.isDefault,
      params: { style: values.style, tone: values.tone || [] },
      sampleVoice: values?.sampleVoice || {},
      sampleText: values?.sampleText || '',
      voicePrompt: !!Object.keys(values?.voicePrompt).length
        ? JSON.stringify(values?.voicePrompt)
        : '',
      creationType: voiceData?.creationType || openAICreateVoice?.creationType,
    };
    setVoiceData({
      ...formVoiceData,
      id: voiceData.id,
      voicePrompt: formVoiceData.voicePrompt ? deepParse(formVoiceData.voicePrompt) : {},
    });
    setFinalSubmitLoading(true);
    const action = voiceData.id ? props.updateVoice : props.saveVoice;
    const sanitizedData = { ...formVoiceData };
    voiceData.id && keysToRemove.forEach((key) => delete sanitizedData[key]);
    const payload = voiceData.id ? { id: voiceData.id, data: sanitizedData } : sanitizedData;
    try {
      await action(payload);
      setShowVoiceNameField(false);
      closeOpenView();
    } catch (error) {
      setFinalSubmitLoading(false);
    } finally {
      props.fetchVoices(voices.paging, voices.filters, voices.sort);
      setFinalSubmitLoading(false);
    }
  };

  const manageAiGeneration = (formProps) => {
    setShowLoading(true);
    setOpenAICreateVoice({
      open: true,
      edit: null,
      creationType: 'ai',
      isPromptView: false,
    });
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'tov-v2',
        payload: {
          text: formProps?.values?.sampleText,
        },
      }),
    );

    socket.off('pipeline:execute:success');
    socket.off('disconnect');
    socket.off('pipeline:execute:error');

    socket.on('pipeline:execute:success', (res) => {
      formProps.setFieldValue('voicePrompt', res?.json);
      dispatch(toggleVisibilityOfProfileTab(true));
      setShowLoading(false);
    });

    const handleDisconnect = () => {
      setShowLoading(false);
      setOpenAICreateVoice({
        open: false,
        edit: null,
        creationType: '',
        isPromptView: false,
      });
      toast.warning('AI service temporarily unavailable');
      socket.off('disconnect', handleDisconnect);
    };

    socket.on('disconnect', handleDisconnect);

    socket.on('pipeline:execute:error', () => {
      setOpenAICreateVoice({
        open: false,
        edit: null,
        creationType: '',
        isPromptView: false,
      });
      setShowLoading(false);
    });
  };

  return (
    <Stack gap={0.5} mb={2}>
      <Form
        initialValues={getInitialValues()}
        validationSchema={Yup.object().shape(getFormValidation())}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              id="tov-form"
              noValidate
            >
              <>
                {openAICreateVoice?.open && !openAICreateVoice?.isPromptView ? (
                  <VoiceCreation
                    voiceData={voiceData}
                    setVoiceData={setVoiceData}
                    isDisabled={isDisabled}
                    showLoading={showLoading}
                    setShowLoading={setShowLoading}
                    analyseCopyPrompts={analyseCopyPrompts}
                    setAnalyseCopyPrompt={setAnalyseCopyPrompt}
                    openAICreateVoice={openAICreateVoice}
                    closeOpenView={closeOpenView}
                    voices={voices}
                    finalSubmitLoading={finalSubmitLoading}
                    setFinalSubmitLoading={setFinalSubmitLoading}
                    showVoiceNameField={showVoiceNameField}
                    formProps={formProps}
                    setShowVoiceNameField={setShowVoiceNameField}
                    setOpenAICreateVoice={setOpenAICreateVoice}
                    showLoadingForAI={showLoadingForAI}
                    manageAiGeneration={manageAiGeneration}
                    {...props}
                  />
                ) : (
                  <>
                    {(!!voices?.data?.length || props.loading?.voices) && (
                      <VoicesGrid
                        setOpenAICreateVoice={setOpenAICreateVoice}
                        openAICreateVoice={openAICreateVoice}
                        voices={voices}
                        fetchVoiceDataById={fetchVoiceDataById}
                        formProps={formProps}
                        {...props}
                      />
                    )}
                    {!voices?.data?.length && !props.loading?.voices && (
                      <EmptyVoice
                        {...props}
                        setOpenAICreateVoice={setOpenAICreateVoice}
                        formProps={formProps}
                        voices={voices}
                      />
                    )}
                  </>
                )}
                <AnalyseCopyModal
                  onCancel={() => {
                    closeOpenView();
                  }}
                  onSubmit={() => {
                    formProps.setTouched({
                      sampleText: true,
                    });
                    formProps.validateForm().then((errors) => {
                      if (errors && !errors?.sampleText) {
                        formProps.setErrors({});
                        manageAiGeneration(formProps);
                      }
                    });
                  }}
                  formProps={formProps}
                  open={
                    openAICreateVoice?.open &&
                    openAICreateVoice?.creationType === 'ai' &&
                    openAICreateVoice?.isPromptView
                  }
                />
              </>
            </form>
          );
        }}
      </Form>
    </Stack>
  );
}

export default VoicesView;
