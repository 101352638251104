import React, { useEffect, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import ManualCreateVoice from './ManualCreateVoice';
import images from 'src/config/images';
import { BackButton, ButtonBaseContainer } from '../../components/Voice/styles';
import CreateVoiceNameModal from '../../components/Voice/VoiceDialog/CreateVoiceNameModal';
import { Loader } from '../../../../components/App';
import VoiceLoading from '../../components/Voice/VoiceDialog/VoiceLoading';
import CreateVoiceDefaultView from './CreateVoiceDefaultView';
import AICreateVoice from './AICreateVoice';
import { useSelector } from 'react-redux';
import { setupDynamicMaxHeight } from '../../../../utils/helper';

const VoiceCreation = ({
  openAICreateVoice,
  isDisabled,
  showLoading,
  closeOpenView,
  voiceData,
  setVoiceData,
  finalSubmitLoading,
  showVoiceNameField,
  setShowVoiceNameField,
  formProps,
  setOpenAICreateVoice,
  setShowLoading,
  showLoadingForAI,
  manageAiGeneration,
}) => {
  const tovSampleText = useSelector((state) => state.app.globals?.tovSampleText);
  const [maxHeight, setMaxHeight] = useState('100vh');

  useEffect(() => {
    setupDynamicMaxHeight(
      setMaxHeight,
      ['.MuiToolbar-root', '.ai-back-button', '.ai-create-voice-header', '.ai-creation-footer'],
      50,
    );
  }, [openAICreateVoice, showLoading, voiceData]);

  const sampleTextList = useMemo(() => {
    if (tovSampleText?.length) {
      return tovSampleText.map((item) => ({ label: item, value: item }));
    }
    return [];
  }, [tovSampleText]);

  const renderBackButton = () => (
    <Box pt={'12px'} pb={'24px'} className={'ai-back-button'}>
      <ButtonBaseContainer onClick={closeOpenView}>
        <Stack direction="row" alignItems="center" gap="16px">
          <img src={images.icons.leftNavIcon} alt="Back" />
          <BackButton>Back</BackButton>
        </Stack>
      </ButtonBaseContainer>
    </Box>
  );

  return (
    <>
      {renderBackButton()}
      {((openAICreateVoice?.creationType === 'manual' && showLoading) || showLoadingForAI) && (
        <Loader />
      )}
      {!showLoading && !showLoadingForAI && (
        <>
          {openAICreateVoice?.creationType === 'manual' && (
            <ManualCreateVoice
              formProps={formProps}
              voiceData={voiceData}
              setVoiceData={setVoiceData}
              setShowVoiceNameField={setShowVoiceNameField}
              sampleTextList={sampleTextList}
            />
          )}
          {openAICreateVoice?.creationType === 'ai' && (
            <AICreateVoice
              maxHeight={maxHeight}
              sampleTextList={sampleTextList}
              voiceData={voiceData}
              setShowLoading={setShowLoading}
              showLoading={showLoading}
              formProps={formProps}
              setOpenAICreateVoice={setOpenAICreateVoice}
              setShowVoiceNameField={setShowVoiceNameField}
              manageAiGeneration={manageAiGeneration}
            />
          )}
          <CreateVoiceNameModal
            formProps={formProps}
            voiceData={voiceData}
            setVoiceData={setVoiceData}
            open={showVoiceNameField}
            setShowVoiceNameField={setShowVoiceNameField}
            onSubmit={formProps.handleSubmit}
            isDisabled={isDisabled}
            loading={finalSubmitLoading}
          />
        </>
      )}
      <VoiceLoading
        open={showLoading && openAICreateVoice?.creationType === 'ai' && !showLoadingForAI}
        reAnalyse={openAICreateVoice?.reAnalyse}
      />
      {showLoading && !showLoadingForAI && openAICreateVoice?.creationType === 'ai' && (
        <CreateVoiceDefaultView voiceData={voiceData} />
      )}
    </>
  );
};

export default VoiceCreation;
