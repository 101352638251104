import { makeStyles } from '@mui/styles';

export const useWelcomeModalStyles = makeStyles((theme) => ({
  root: (props) => ({
    '&.MuiDialog-root': {
      '& .header-container': {
        '& .title': {
          fontWeight: 400,
          textTransform: 'inherit',

          '& strong': {
            fontWeight: 500,
            textTransform: 'uppercase',
          },
        },
      },

      '& .footer-container': {
        '& button': {
          maxWidth: 'max-content',
          marginInline: 'auto',
        },
      },

      '& .main-container': {
        [theme.breakpoints.down('md')]: {
          '& .description': {
            gap: 16,
          },
        },
      },
    },
  }),
}));
