import { Dialog, Box } from '@mui/material';
import {
  BlackChip,
  BlackChipText,
  CustomerCardBlockModal,
  CustomerCardBlockModalTitle,
  CustomerTitleText,
  EmptyCustomerCard,
  FieldChip,
  FieldChipText,
  ICPFieldViews,
  StyledCloseIcon,
  useStyles,
} from './style';
import images from '../../../../config/images';
import { fieldList, getBackGroundColor, getFieldValueForMoreDetail } from './utils';

const ICPMoreDetailsModal = ({ isDetailModal, handleClose }) => {
  const classes = useStyles();

  const moreDetailList = [];

  fieldList
    .map((field) => {
      if (isDetailModal?.moreDetails?.hasOwnProperty(field?.key)) {
        const fieldValueData = getFieldValueForMoreDetail(isDetailModal?.moreDetails[field?.key]);
        if (Array.isArray(fieldValueData)) {
          fieldValueData.map((item) => {
            moreDetailList.push({
              ...field,
              value: item,
            });
          });
        } else {
          moreDetailList.push({
            ...field,
            value: fieldValueData,
          });
        }
      }
      return field;
    })

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Dialog
        className={classes.icpMoreDetailsModalContainer}
        open={isDetailModal.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <EmptyCustomerCard>
          <CustomerCardBlockModal
            sx={{ background: getBackGroundColor(isDetailModal?.moreDetails?.profileType) }}
          >
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              width={'100%'}
              padding={'24px 24px 12px 24px'}
            >
              <StyledCloseIcon onClick={handleClose} />
            </Box>
            <CustomerCardBlockModalTitle>
              <BlackChip>
                <BlackChipText sx={{ fontSize: '10px' }}>
                  {isDetailModal?.moreDetails?.profileType}
                </BlackChipText>
              </BlackChip>
              <CustomerTitleText>{isDetailModal?.moreDetails?.name}</CustomerTitleText>
            </CustomerCardBlockModalTitle>
          </CustomerCardBlockModal>
          <ICPFieldViews sx={{ padding: '24px' }}>
            {moreDetailList
              ?.filter((item) => item?.value)
              ?.map((field, index) => (
                <FieldChip>
                  <img src={images.icp[field?.icon]} alt={''} />
                  <FieldChipText>{field?.value || field?.label}</FieldChipText>
                </FieldChip>
              ))}
          </ICPFieldViews>
        </EmptyCustomerCard>
      </Dialog>
    </Box>
  );
};
export default ICPMoreDetailsModal;
