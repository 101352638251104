import React, { useMemo, useState, useRef, useEffect, Fragment } from 'react';
import { Box, Typography, Alert, Tabs, Tab, Backdrop, Card, Stack } from '@mui/material';
import { Spacer, Form, Button } from 'src/components/shared';
import { Accordion, Empty, Loader } from 'src/components/App';
import { SubmitButtonWrapper } from './styles';
import { Email, SelectTemplate, Call, LinkedIn, General } from './Input';
import ContactDetails from 'src/modules/contacts/components/ContactDetails';
import { getTaskTypeIcon, getTaskTypeText } from 'src/modules/tasks/utils/tasksUtils';
import SMS from './Input/SMS';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ActivityItem from 'src/modules/contacts/components/ContactActivities/ActivityItem';
import SequenceItem from 'src/modules/contacts/components/Contact/sequence';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getContactFields } from 'src/modules/app/actions/appSelector';
import Attributes from 'src/modules/contacts/components/Contact/attributes';
import {
  checkEnrolmentAndFetchErrors,
  fetchContact,
  fetchSequenceEnrollment,
  postContactDetails,
  putEnrollment,
} from 'src/modules/contacts/actions/contactActions';

function EnrolmentPersonalizeForm({
  contact,
  sequence,
  steps,
  handleSubmit,
  action,
  sequenceEnrollment,
  contactNote,
  fields,
  personaliseEnrollment,
  enrolments,
  ...props
}) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const loadingStep = useSelector((state) => state.enrolment.loading);
  const [loading, setLoading] = useState(false);
  const [selectedEmailState, setSelectedEmailState] = useState(enrolments?.selectedEmailId);
  const [currentTab, setCurrentTab] = useState(0);

  const initialValues = useMemo(() => {
    if (!steps || !Object.keys(steps).length) return {};

    const values = {};
    Object.keys(steps).forEach((key) => {
      const task = steps[key];
      const baseEmailTemplate = {
        templateId: task?.template?.id,
        subject: task?.template?.subject,
        content: task?.template?.content,
        contentOriginal: task?.template?.contentOriginal || task?.template?.content,
        files: task?.template?.files || [],
        attachments: task?.template?.attachments || [],
        taskType: task?.taskType,
      };

      switch (task.taskType) {
        case 'email':
          values[key] = baseEmailTemplate;
          break;

        case 'selectEmailTemplate':
          values[key] = {
            ...baseEmailTemplate,
            templateId: task?.template || {},
            subject: task?.template?.subject || '',
            content: task?.template?.content || '',
          };
          break;

        case 'general':
        case 'call':
        case 'sms':
          values[key] = {
            description: task?.description,
            content: task?.description,
            taskType: task?.taskType,
          };
          break;

        case 'linkedinMessage':
        case 'linkedinConnection':
        case 'linkedinViewProfile':
        case 'linkedinMail':
          values[key] = baseEmailTemplate;
          break;
      }
    });
    return values;
  }, [steps]);

  useEffect(() => {
    if (!contact?.emails?.length || enrolments?.selectedEmailId) {
      if (enrolments?.selectedEmailId) {
        setSelectedEmailState(enrolments.selectedEmailId);
      }
      return;
    }

    const emailType = sequence?.audienceEmailType;
    const primaryEmail = contact.emails.find(
      (email) => email.type === emailType && email.isPrimary === true,
    );

    if (primaryEmail) {
      setSelectedEmailState(primaryEmail.id);
    }
  }, [contact?.emails, sequence?.audienceEmailType, enrolments?.selectedEmailId]);

  const onSubmit = async (data, resume = true) => {
    let formData = JSON.parse(JSON.stringify(data));
    const selectedEmail = formData.selectedEmail;
    delete formData.selectedEmail;
    const values = { selectedEmail };
    if (Object.keys(formData) && Object.keys(formData).length) {
      Object.keys(formData).map((key) => {
        const filesArr =
          formData[key]?.attachments && formData[key]?.attachments.length
            ? formData[key]?.attachments.filter((item) => item.id).map((item) => item.id)
            : [];
        if (formData[key]?.taskType === 'selectEmailTemplate' && !formData[key]?.templateId?.id)
          return null;
        values[key] = {
          ...formData[key],
          attachments: filesArr,
          files: filesArr,
        };
      });
    }

    setLoading(true);
    await handleSubmit(values, resume);
    setLoading(false);
  };

  const stepsArr = useMemo(() => {
    if (!steps) return [];

    return Object.keys(steps).reduce((acc, currentValue) => {
      const step = steps[currentValue];
      step.taskId = currentValue;
      if ('taskOrderInSequence' in step) {
        const insertIndex = acc.findIndex(
          (item) => item.taskOrderInSequence > step.taskOrderInSequence,
        );
        insertIndex === -1 ? acc.push(step) : acc.splice(insertIndex, 0, step);
      } else {
        acc.push(step);
      }
      return acc;
    }, []);
  }, [steps]);

  return (
    <Form
      initialValues={{
        selectedEmail: selectedEmailState,
        ...initialValues,
      }}
      enableReinitialize={true}
      onSubmit={(values) => onSubmit(values, true)}
      innerRef={formRef}
    >
      {({ values, ...formProps }) => {
        return (
          <Fragment>
            <Box px={2} mt={2}>
              {formProps?.errors?.selectedEmail && formProps?.errors?.selectedEmail !== '' && (
                <>
                  <Alert severity="error" sx={{ padding: '4px 12px' }}>
                    {formProps?.errors?.selectedEmail}
                  </Alert>
                  <Spacer x={2} y={2} />
                </>
              )}
              <Typography color="textSecondary">Confirm details for your outreach</Typography>
              <Box mt={1}>
                <ContactDetails
                  contact={contact}
                  showSelection={true}
                  onChange={() => dispatch(checkEnrolmentAndFetchErrors())}
                  selected={values.selectedEmail}
                  type={'dropdown'}
                  onSelectionChange={(value) => {
                    // formProps.setFieldValue('selectedEmail', value);
                    setSelectedEmailState(value);
                  }}
                  isEnroll={true}
                  {...props}
                />
              </Box>
            </Box>

            <Tabs
              value={currentTab}
              onChange={(e, val) => {
                setCurrentTab(val);
              }}
              sx={{
                '.Mui-selected': {
                  color: '#1976d2',
                },
                '.MuiTabs-indicator': {
                  backgroundColor: '#1976d2',
                },
              }}
              variant="fullWidth"
            >
              <Tab sx={{ textTransform: 'initial', fontSize: '15px' }} label="Personalise" />
              <Tab sx={{ textTransform: 'initial', fontSize: '15px' }} label="Other Info" />
            </Tabs>

            {currentTab === 0 && steps && stepsArr.length ? (
              <>
                {stepsArr.map((task, index) => {
                  return (
                    <Accordion
                      key={`sequence-personalize-step-${index}`}
                      title={getTaskTypeText(task?.taskType)}
                      icon={getTaskTypeIcon(task?.taskType)}
                      expanded={index === 0 ? true : false}
                      rotateIcon={false}
                    >
                      <Box px={2}>
                        {task?.taskType == 'email' ? (
                          <Email
                            assignedTo={enrolments?.assignedTo}
                            contact={contact}
                            task={task}
                            taskId={task?.taskId}
                            formRef={formRef}
                            formProps={formProps}
                            data={values[task?.taskId]}
                          />
                        ) : null}
                        {task?.taskType == 'general' ? (
                          <General
                            contact={contact}
                            task={task}
                            taskId={task?.taskId}
                            assignedTo={enrolments?.assignedTo}
                            formRef={formRef}
                            data={values[task?.taskId]}
                          />
                        ) : null}
                        {task?.taskType == 'selectEmailTemplate' ? (
                          <SelectTemplate
                            contact={contact}
                            task={task}
                            taskId={task?.taskId}
                            formRef={formRef}
                            assignedTo={enrolments?.assignedTo}
                            data={values[task?.taskId]}
                          />
                        ) : null}
                        {task?.taskType == 'call' ? (
                          <Call
                            contact={contact}
                            task={task}
                            taskId={task?.taskId}
                            formRef={formRef}
                            assignedTo={enrolments?.assignedTo}
                            data={values[task?.taskId]}
                          />
                        ) : null}
                        {task?.taskType == 'linkedinMessage' ||
                        task?.taskType == 'linkedinConnection' ||
                        task?.taskType == 'linkedinViewProfile' ||
                        task?.taskType == 'linkedinMail' ? (
                          <LinkedIn
                            contact={contact}
                            task={task}
                            taskId={task?.taskId}
                            formRef={formRef}
                            assignedTo={enrolments?.assignedTo}
                            data={values[task?.taskId]}
                          />
                        ) : null}
                        {task?.taskType === 'sms' ? (
                          <SMS
                            contact={contact}
                            task={task}
                            taskId={task?.taskId}
                            formRef={formRef}
                            assignedTo={enrolments?.assignedTo}
                            data={values[task?.taskId]}
                          />
                        ) : null}
                      </Box>
                    </Accordion>
                  );
                })}
              </>
            ) : null}

            {loadingStep?.steps || sequenceEnrollment?.length === 0 ? (
              <div>
                <Loader />
              </div>
            ) : (
              currentTab === 1 && (
                <Box>
                  <Accordion title="Sequence History" expanded={false}>
                    <Box sx={{ padding: '16px 12px' }}>
                      {sequenceEnrollment?.length ? (
                        <>
                          {sequenceEnrollment.map((sequence, index) => (
                            <SequenceItem
                              sequence={sequence}
                              putEnrollment={props.putEnrollment}
                              key={`sequence-item-${sequence.id}-${index}`}
                            />
                          ))}
                        </>
                      ) : (
                        <Empty title="No sequences found" />
                      )}
                    </Box>
                  </Accordion>
                  <Accordion title="Activity" expanded={false}>
                    {contactNote?.length > 0 ? (
                      contactNote.map((note, index) => (
                        <ActivityItem data={note} key={`contact-activity-${index}`} />
                      ))
                    ) : (
                      <Empty title="No activities found!" />
                    )}
                  </Accordion>
                  <Accordion title="Attributes" expanded={false}>
                    <Box sx={{ padding: 3 }} display={'flex'} gap={2} flexDirection={'column'}>
                      <Attributes contact={contact} fields={fields} />
                    </Box>
                  </Accordion>
                </Box>
              )
            )}

            <Spacer x={10} y={10} />

            <SubmitButtonWrapper>
              {action === 'edit' && (
                <>
                  <Button
                    fullWidth
                    variant="outlined"
                    loading={loading}
                    color="secondary"
                    sx={{ flex: 1.3 }}
                    onClick={() => {
                      onSubmit(values, false);
                    }}
                  >
                    Save Edits
                  </Button>
                  <Spacer x={2} y={2} />
                </>
              )}

              <Button
                fullWidth
                variant="contained"
                loading={loading}
                color="secondary"
                sx={{ flex: 1.7 }}
                onClick={() => {
                  formProps.submitForm();
                }}
              >
                {action === 'edit'
                  ? 'Save and resume Outreach'
                  : !personaliseEnrollment
                  ? 'Enrol'
                  : `Save edits`}
                &nbsp; {(action === 'edit' || personaliseEnrollment) && <ArrowForwardRoundedIcon />}
              </Button>
            </SubmitButtonWrapper>
          </Fragment>
        );
      }}
    </Form>
  );
}

const mapStateToProps = (state) => ({
  sequenceEnrollment: state.contacts.sequenceEnrollment,
  contactNote: state.contacts.contactNote,
  fields: getContactFields(state),
});

const mapDispatchToProps = (dispatch) => ({
  putEnrollment: (id, enrollment) => dispatch(putEnrollment(id, enrollment)),
  postContactDetails: (contactId, type, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postContactDetails(contactId, type, payload, resolve, reject));
    });
  },
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
  fetchContact: (contactId) => dispatch(fetchContact(contactId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentPersonalizeForm);
