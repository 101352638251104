import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Stack, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccountCreditLimit from '../../components/DataCredit/AccountCreditLimit';
import UserCreditLimit from '../../components/DataCredit/UserCreditLimit';
import CreditAllocation from '../../components/DataCredit/CreditAllocation';
import { useDataProviders } from '../../utils/dataProviderStore';

function Management() {
  const { creditAllocation, defaultCreditLimit, fetchCreditAllocation } = useDataProviders();
  const user = useSelector((state) => state.auth.user);

  const myCreditAllocation = useMemo(() => {
    if (user && user.id && creditAllocation && creditAllocation.length) {
      return (
        creditAllocation.filter((item) => item.user.id === user.id)[0]?.credits ||
        defaultCreditLimit
      );
    }
    return defaultCreditLimit;
  }, [user, creditAllocation, defaultCreditLimit]);

  useEffect(() => {
    fetchCreditAllocation();
  }, []);

  return (
    <>
      <Stack direction="flex" alignItems="center" justifyContent="space-between">
        <Typography color="textSecondary">
          Control how you spend on data each month
        </Typography>
        {/* <Button
          color="secondary"
          sx={{ alignItems: 'center', gap: '4px', textTransform: 'initial' }}
        >
          Buy more credits <OpenInNewIcon />
        </Button> */}
      </Stack>

      <Spacer y={3} />
      <AccountCreditLimit user={user} limit={myCreditAllocation} />
      <Spacer y={3} />
      <UserCreditLimit defaultCreditLimit={defaultCreditLimit} />
      <Spacer y={3} />
      <CreditAllocation
        defaultCreditLimit={defaultCreditLimit}
        creditAllocation={creditAllocation}
      />
    </>
  );
}

export default Management;
