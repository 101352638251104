import React from 'react';
import { Stack } from '@mui/material';
import { CustomModal } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/styles';
import { Button } from 'src/components/shared';
import images from 'src/config/images';

const GatedModal = ({
  open,
  onClose,
  type = 'vertical',
  showGlobe = true,
  renderHeader,
  renderFooter,
  children,
  size = 'md',
  image,
  onCloseModal,
  ...props
}) => {
  const theme = useTheme();
  type = type?.toLowerCase() === 'horizontal' ? 'Horizontal' : 'Vertical';
  const isHorizontal = type === 'Horizontal';
  const direction = isHorizontal ? 'row-reverse' : 'column';
  return (
    <CustomModal open={open} onClose={onClose} size={size} isHorizontal={isHorizontal} {...props}>
      <Stack className="content-container" direction={direction}>
        {(onClose || onCloseModal) && (
          <Button
            iconButton
            className="close-btn"
            onClick={() => {
              if (typeof onClose === 'function') {
                onClose();
              }
              if (typeof onCloseModal === 'function') {
                onCloseModal();
              }
            }}
          >
            <CloseIcon sx={{ color: theme.palette.text.primary }} />
          </Button>
        )}

        {showGlobe && (
          <img key={images} src={image ?? images.LMS[`gated${type}Bg`]} className="header-bg" />
        )}

        <Stack className="header-container">{renderHeader}</Stack>

        <Stack className="main-container">
          {children}
          {renderFooter && <Stack className="footer-container">{renderFooter}</Stack>}
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default GatedModal;
