import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Form } from 'src/components/shared';
import { fetchPopulateEnqueueTemplate } from '../../../actions/enrolmentActions';
import { debounce, throttle } from 'lodash';
import { useState } from 'react';
import { replacementVarialbleREGX } from '../../../utils/sequenceHelper';
import { useSingleDebouncedFunction } from '../utlis';

function SMS({ task, taskId, contact, assignedTo, formRef }) {
  const dispatch = useDispatch();
  const [editorRef, setEditorRef] = useState(null);

  const moveCursorToEnd = () => {
    try {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
    } catch (error) {}
  };

  const { debouncedFunction, cancel } = useSingleDebouncedFunction();

  const onChange = async (val, type) => {
    if (formRef.current) {
      let data = formRef.current.values;
      data = data[taskId];
      const isContent = type === 'content';

      cancel();

      const hasReplacementVariable = replacementVarialbleREGX.test(val);

      if (hasReplacementVariable) {
        const executeDefault = () =>
          populateTemplate(
            contact.id,
            null,
            isContent ? data.subject : val,
            isContent ? val : data.content,
            assignedTo,
          );
        debouncedFunction(executeDefault, 1000)();
      }
      if (isContent && hasReplacementVariable) {
        setTimeout(() => {
          moveCursorToEnd();
        }, 100);
      }
      const customWaitTime = 4000;
      const executeCustom = () =>
        populateTemplate(
          contact.id,
          null,
          isContent ? data.subject : val,
          isContent ? val : data.content,
          assignedTo,
        );

      debouncedFunction(executeCustom, customWaitTime)();
    }
  };

  const getChildEditorRef = (editorRef) => {
    if (editorRef && editorRef !== null) {
      setEditorRef(editorRef);
    }
  };

  useEffect(() => {
    const templateId = task?.template?.id || '';
    let data = formRef.current.values;
    data = data[taskId];
    if (templateId && templateId !== '' && contact && contact.id && contact.id !== '') {
      populateTemplate(contact?.id, templateId, data?.subject, data?.content);
    }
  }, [contact]);

  const populateTemplate = (contactId, templateId, subject, content) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchPopulateEnqueueTemplate(
          taskId,
          contactId,
          templateId,
          subject,
          content,
          assignedTo,
          resolve,
          reject,
        ),
      );
    });
  };

  return (
    <Box mb={2} mt={2}>
      <Form.Field.TextEditor
        name={`${taskId}.description`}
        onChange={(val) => onChange(val, 'content')}
        showFontStyle={false}
        showFontEditor={false}
        showAlignProps={false}
        showListProps={false}
        showAttachments={false}
        getChildEditorRef={getChildEditorRef}
      />
    </Box>
  );
}

export default SMS;
