export const ProfileTabs = [
  {
    id: 'details',
    name: 'Details',
  },
  {
    id: 'account',
    name: 'Account',
  },
  {
    id: 'voice',
    name: 'Tone of voice',
  },
  {
    id: 'icp',
    name: 'ICP',
  },
  {
    id: 'outreachSettings',
    name: 'Outreach settings',
  },
  // {
  //   id: 'customVariables',
  //   name: 'Custom Variables',
  // },
  {
    id: 'notification',
    name: 'Notification',
  },
  {
    id: 'security',
    name: 'Security',
  },
  // {
  //   id: 'signature',
  //   name: 'Signature',
  // },
];

export const ProfileTabsLms = [
  {
    id: 'details',
    name: 'Details',
  },
  {
    id: 'account',
    name: 'Account',
  },
  {
    id: 'icp',
    name: 'ICP',
  },
  {
    id: 'notification',
    name: 'Notification',
  },
  {
    id: 'security',
    name: 'Security',
  },
];

export const ProfileLmsOutTabs = [
  {
    id: 'details',
    name: 'Details',
  },
  {
    id: 'account',
    name: 'Account',
  },
  {
    id: 'voice',
    name: 'Tone of voice',
  },
  {
    id: 'icp',
    name: 'ICP',
  },
  {
    id: 'outreachSettings',
    name: 'Outreach settings',
  },
  {
    id: 'notification',
    name: 'Notification',
  },
  {
    id: 'security',
    name: 'Security',
  },
  // {
  //   id: 'signature',
  //   name: 'Signature',
  // },
];
