import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, Select, TextField, Typography } from '@mui/material';
import { Button, Form, Modal, Spacer } from 'src/components/shared';
import { Stack } from '@mui/material';
import { useDataProviders } from '../../utils/billingStore';

const licenceSeatsKeys = {
  outreachMonthly: { used: 'outreachMonthly', purchased: 'outreachMonthly_consumed' },
  outreachAnnual: { used: 'outreachAnnual', purchased: 'outreachAnnual_consumed' },
  lmsmonthly: { used: 'lmsMonthly', purchased: 'lmsMonthly_consumed' },
  lmsannual: { used: 'lmsAnnual', purchased: 'lmsAnnual_consumed' },
  oneMembershipAnnual: ['lmsAnnual', 'outreachAnnual'],
  oneMembershipMonthly: ['lmsMonthly', 'outreachMonthly'],
};

const oneMembershipKeys = ['oneMembershipMonthly', 'oneMembershipAnnual'];

function AssingLicenses({
  showLicenses,
  creditRechargeType,
  subscriptions,
  onClose,
  initiatePayment,
  loading,
  tenant,
  addTrialSubscriptions,
}) {
  const defaultValue =
    showLicenses?.plan?.planId === 'Trial-plan-7-day-GBP-Weekly' ||
    showLicenses?.plan?.planId === 'Starter-Monthly-Plan-GBP-Monthly'
      ? 1
      : 0;
  const [costPerUnit, setCostPerUnit] = useState({
    value: defaultValue,
    min: defaultValue,
  });
  const [formValues, setFormValues] = React.useState({
    plan: 0,
  });

  const { roleplayData, getRolePlayData, getHostedPage } = useDataProviders();

  const handleOnChange = (e) => {
    const { name, value } = e.target; // Extract field name and value
    setFormValues({
      ...formValues,
      [name]: value, // Update the specific field in state
    });
  };

  const handleSubmit = (values) => {
    if (
      showLicenses?.plan?.planId === 'Trial-plan-7-day-GBP-Weekly' ||
      showLicenses?.plan?.planKey === 'Starter-Monthly-Plan-GBP-Monthly'
    ) {
      const payload = {
        item_price_id: showLicenses?.plan.planId,
        quantity: +formValues?.plan,
      };
      addTrialSubscriptions(showLicenses?.subscription?.id, payload);
      onClose();
      setCostPerUnit({
        min: defaultValue,
        value: defaultValue,
      });
    } else {
      initiatePayment(
        showLicenses?.plan?.planId,
        showLicenses?.subscription,
        false,
        costPerUnit?.value,
      );
    }
  };

  useEffect(() => {
    getRolePlayData();
    if (subscriptions.hasOwnProperty('seats') && showLicenses?.planName) {
      const planKey = showLicenses?.planName;
      if (oneMembershipKeys.includes(planKey)) {
        const keysToGetData = licenceSeatsKeys[planKey];
        let value = defaultValue;
        keysToGetData?.forEach((item) => {
          if (subscriptions.seats[item] > value) {
            value = subscriptions.seats[item];
          }
        });
        setCostPerUnit({ min: value, value });
      } else {
        const keysToGetData = licenceSeatsKeys[planKey];
        const value = subscriptions.seats[keysToGetData?.used] || 0;
        setCostPerUnit({ min: value, value });
      }
    }
  }, [showLicenses?.open]);

  return (
    <Modal
      open={showLicenses?.open}
      sx={{
        '& .MuiDialogTitle-root': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      }}
      title={
        <Stack gap="6px">
          <Typography fontSize="18px" fontWeight="500">
            Assign Licenses
          </Typography>
        </Stack>
      }
      onClose={() => {
        onClose();
        setCostPerUnit({
          min: defaultValue,
          value: defaultValue,
        });
      }}
    >
      <Spacer x={2} y={2} />
      <Spacer x={1} y={1} />
      <Form
        enableReinitialize
        initialValues={{
          plan: costPerUnit.value || 0, // Set the default value dynamically
        }}
        validationSchema={Yup.object().shape({
          plan: Yup.number()
            .min(costPerUnit.min, `The value must be greater than or equal to ${costPerUnit.min}`)
            .typeError('Please enter a valid number') // Ensure valid number input
            .required('Please enter a license quantity!'),
        })}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          return (
            <>
              <TextField
                required
                value={formProps.values.plan}
                onChange={(e) => {
                  formProps.handleChange(e);
                  handleOnChange(e);
                }}
                onBlur={formProps.handleBlur}
                fullWidth
                placeholder="Assign Licenses *"
                variant="outlined"
                name="plan"
                min={costPerUnit?.min}
                error={formProps.errors.plan}
                helperText={formProps.errors.plan && formProps.errors.plan}
                errorBorder
              />
              <Spacer x={1} y={1} />
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={formProps.submitForm}
                  loading={loading}
                >
                  Confirm
                </Button>
              </Box>
            </>
          );
        }}
      </Form>
    </Modal>
  );
}

export default AssingLicenses;
