import React, { useMemo } from 'react';
import { Typography, Box, Stack, Divider, ListItemIcon, ListItem } from '@mui/material';
import { Form, Spacer } from 'src/components/shared';
import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';
import moment from 'moment';
import EmailIcon from '@mui/icons-material/Email';
import { capitalizeFirstLetter } from 'src/utils/helper';

function Item({ task, messageKey, checked, selected, ...props }) {
  const { contact, sequence } = task;

  const name = capitalizeName(`${contact?.fname} ${contact?.lname}`);

  const handleClick = () => {
    props.handleClick(task);
  };

  const today = moment();
  const date = moment(task?.scheduledOn);
  const diff = today.diff(date, 'days');
  let dateStr;
  if (diff > 3) {
    dateStr = `${moment(date).format('MMM D | HH:MM A')}`;
  } else {
    dateStr = `${moment(date).fromNow()}`;
  }

  const emailType = useMemo(
    () => (task?.taskType === 'email' ? 'automated' : 'manual'),
    [task?.taskType],
  );

  const color = emailType === 'manual' ? '#EC407A' : '#1976D2';

  return (
    <>
      <ListItem sx={selected === task.id ? { backgroundColor: 'rgba(25, 118, 210, 0.06)' } : {}}>
        <ListItemIcon sx={{ minWidth: '32px', width: '32px' }}>
          <Form.Field.Checkbox name={task.id} size="small" label={<Box></Box>} />
        </ListItemIcon>

        <Box width="100%" onClick={handleClick} pl={-0.5}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Typography>{name}</Typography>
            </Stack>

            <Typography variant="caption" color="textSecondary">
              {dateStr}
            </Typography>
          </Stack>
          {sequence && sequence.name && (
            <Typography variant="body2" color="secondary">
              {sequence.name}
            </Typography>
          )}

          <Spacer y={0.5} x={0.5} />
          {task?.subject && task?.subject !== '' && (
            <Typography variant="body1" color="textSecondary">
              {task.subject}
            </Typography>
          )}

          <Box display="flex" justifyContent="flex-end" mt={0.5}>
            <EmailIcon sx={{ fontSize: '18px', fill: color }} />
            <Spacer x={1} y={1} />
            <Typography variant="caption" color={color}>
              {capitalizeFirstLetter(emailType)} email
            </Typography>
          </Box>
        </Box>
      </ListItem>
      <Divider />
    </>
  );
}

export default Item;
