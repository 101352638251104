import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Alert } from '@mui/material';
import { Form } from 'src/components/shared';
import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { fetchTemplateDetails } from 'src/modules/admin/api/adminApi';
import { debounce } from 'lodash';
import {
  fetchPopulateEnqueueTemplate,
  setPersonalizeStep,
} from '../../../actions/enrolmentActions';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import { useState } from 'react';
import { checkHasReplacementVariable, useStepError } from '../../../utils/sequenceHelper';
import { useSingleDebouncedFunction } from '../utlis';

const SelectEmail = React.memo(function SelectEmail({
  task,
  taskId,
  contact,
  formRef,
  assignedTo,
  data,
}) {
  const dispatch = useDispatch();
  const [editorRef, setEditorRef] = useState(null);
  const [loading, setLoading] = useState(false);

  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));

  const moveCursorToEnd = () => {
    try {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
    } catch (error) {}
  };

  let values = formRef.current ? formRef.current.values : {};
  values = values[taskId] || {};
  const showFields = useMemo(() => {
    return values?.templateId && values?.templateId?.id && values?.templateId?.id !== '';
  }, [values]);

  const { debouncedFunction, cancel } = useSingleDebouncedFunction();

  const onChange = async (val, type) => {
    if (formRef.current) {
      let data = formRef.current.values;
      data = data[taskId];
      const templateId = task?.template?.id || '';
      const isContent = type === 'content';

      cancel();

      const hasReplacementVariable = checkHasReplacementVariable(val);

      if (hasReplacementVariable) {
        const executeDefault = () =>
          populateTemplate(
            contact.id,
            templateId,
            isContent ? data.subject : val,
            isContent ? val : data.content,
            assignedTo,
          );
        debouncedFunction(executeDefault, 1000)();
      }

      if (isContent && hasReplacementVariable) {
        setTimeout(() => {
          moveCursorToEnd();
        }, 100);
      }

      const customWaitTime = 4000;
      const executeCustom = () =>
        populateTemplate(
          contact.id,
          templateId,
          isContent ? data.subject : val,
          isContent ? val : data.content,
          assignedTo,
        );

      debouncedFunction(executeCustom, customWaitTime)();
    }
  };

  const getChildEditorRef = (editorRef) => {
    if (editorRef && editorRef !== null) {
      setEditorRef(editorRef);
    }
  };

  useEffect(() => {
    const templateId = task?.template?.id || '';
    let data = formRef.current.values;
    data = data[taskId];
    if (templateId && templateId !== '' && contact && contact.id && contact.id !== '') {
      populateTemplate(contact?.id, templateId, data?.subject, data?.content);
    }
  }, [contact]);

  const errorString = useStepError(task, data, contact);

  const defOptions = useMemo(() => {
    return task?.template ? [task?.template] : [];
  }, [task]);

  const populateTemplate = (contactId, templateId, subject, content) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchPopulateEnqueueTemplate(
          taskId,
          contactId,
          templateId,
          subject,
          content,
          assignedTo,
          resolve,
          reject,
        ),
      );
    });
  };

  const handleTemplateChange = async (data) => {
    try {
      const templateId = data.id;
      const template = await fetchTemplateDetails(templateId);
      dispatch(
        setPersonalizeStep(taskId, {
          template: template.template,
        }),
      );
      if (templateId && templateId !== '' && contact && contact.id !== '') {
        populateTemplate(contact.id, templateId, data?.subject, data?.content);
      }
    } catch (error) {}
  };

  return (
    <Box mb={2}>
      <Form.Field.AutoComplete
        options={defOptions}
        fullWidth
        // disableClearable={true}
        clearOnEscape={true}
        name={`${taskId}.templateId`}
        placeholder="Select template"
        remoteMethod={(val) => {
          return fetchTemplatesLookup(val, 'email');
        }}
        onChange={handleTemplateChange}
        optLabel="name"
        optValue="id"
        variant="outlined"
      />

      {showFields ? (
        <>
          {errorString && errorString !== '' && !loading ? (
            <Alert severity="error" sx={{ padding: '4px 12px', marginTop: '8px' }}>
              {errorString}
            </Alert>
          ) : null}

          <Form.Field.Input
            variant="outlined"
            name={`${taskId}.subject`}
            label="Subject"
            fullWidth={true}
            showPicker={true}
            onChange={(val) => onChange(val, 'subject')}
            options={replacementVariables}
            optValue="value"
            optLabel="text"
            emojiPicker={true}
          />
          <Form.Field.TextEditor
            name={`${taskId}.content`}
            onChange={(val) => onChange(val, 'content')}
            getChildEditorRef={getChildEditorRef}
          />
          <Form.Field.File name={`${taskId}.attachments`} maxSize={8} />
        </>
      ) : null}
    </Box>
  );
});

export default SelectEmail;
