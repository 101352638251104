export const RESET_STORE = 'RESET_STORE';

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_APP_DATA_LOADING = 'SET_APP_DATA_LOADING';
export const SET_HEADER = 'SET_HEADER';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const FETCH_GLOBALS = 'FETCH_GLOBALS';
export const FETCH_TASK_COUNTS = 'FETCH_TASK_COUNTS';
export const SET_TASK_COUNTS = 'SET_TASK_COUNTS';
export const SET_GLOBALS = 'SET_GLOBALS';

export const FETCH_TENANT_CONFIG = 'FETCH_TENANT_CONFIG';
export const FETCH_CRM_FIELDS = 'FETCH_CRM_FIELDS';
export const SET_TENANT_CONFIG = 'SET_TENANT_CONFIG';

export const TOGGLE_CONTACT_FORM = 'TOGGLE_CONTACT_FORM';
export const TOGGLE_SEQUENCE_FORM = 'TOGGLE_SEQUENCE_FORM';
export const TOGGLE_EMAIL_FORM = 'TOGGLE_EMAIL_FORM';
export const TOGGLE_TASK_FORM = 'TOGGLE_TASK_FORM';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const TOGGLE_NOTIFICATIONS_DRAWER = 'TOGGLE_NOTIFICATIONS_DRAWER';

export const FETCH_REPLACEMENT_VARIABLES = 'FETCH_REPLACEMENT_VARIABLES';
export const SET_REPLACEMENT_VARIABLES = 'SET_REPLACEMENT_VARIABLES';

/* Attributes Types */
export const HANDLE_ATTRIBUTE_DRAG_DROP = 'HANDLE_ATTRIBUTE_DRAG_DROP';
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
export const SET_TENANT_FIELDTYPES = 'SET_TENANT_FIELDTYPES';
export const SET_CRM_NOTES = 'SET_CRM_NOTES';
export const SET_SR_NOTES = 'SET_SR_NOTES';
export const FETCH_CRM_NOTES = 'FETCH_CRM_NOTES';
export const FETCH_SR_NOTES = 'FETCH_SR_NOTES';
export const PUT_ATTRIBUTE = 'PUT_ATTRIBUTE';
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const POST_ATTRIBUTE = 'POST_ATTRIBUTE';

export const FETCH_SEQ_DASHBOARDS = 'FETCH_SEQ_DASHBOARDS';
export const FETCH_SEQ_DASHBOARDS_USER = 'FETCH_SEQ_DASHBOARDS_USER';
export const FETCH_SEQ_DASHBOARDS_TEAM = 'FETCH_SEQ_DASHBOARDS_TEAM';
export const FETCH_SEQ_DASHBOARDS_REPORT = 'FETCH_SEQ_DASHBOARDS_REPORT';
export const FETCH_ACTIVITY_DASHBOARDS = 'FETCH_ACTIVITY_DASHBOARDS';
export const FETCH_ACTIVITY_DASHBOARDS_CHART = 'FETCH_ACTIVITY_DASHBOARDS_CHART';

export const FETCH_DASHBOARDS = 'FETCH_DASHBOARDS';
export const SET_DASHBOARDS = 'SET_DASHBOARDS';
export const POST_DASHBOARD = 'POST_DASHBOARD';
export const PUT_DASHBOARD = 'PUT_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const SET_ACTIVE_DASHBOARD = 'SET_ACTIVE_DASHBOARD';

export const FETCH_PANELS = 'FETCH_PANELS';
export const SET_PANELS = 'SET_PANELS';
export const POST_PANEL = 'POST_PANEL';
export const PUT_PANEL = 'PUT_PANEL';
export const DELETE_PANEL = 'DELETE_PANEL';
export const PANEL_BULK_ADD_UPDATE = 'PANEL_BULK_ADD_UPDATE';

export const FETCH_PANEL_CONTACTS = 'FETCH_PANEL_CONTACTS';
export const SET_PANEL_CONTACTS = 'SET_PANEL_CONTACTS';
export const REMOVE_CONTACT_FROM_PANEL = 'REMOVE_CONTACT_FROM_PANEL';
export const FETCH_DASHBOARD_CONTACTS = 'FETCH_DASHBOARD_CONTACTS';
export const SET_DASHBOARD_CONTACTS = 'SET_DASHBOARD_CONTACTS';
export const HANDLE_DASHBOARD_DRAG_DROP = 'HANDLE_DASHBOARD_DRAG_DROP';
export const HANDLE_ADD_CONTACT_TO_PANEL = 'HANDLE_ADD_CONTACT_TO_PANEL';
export const HANDLE_DASHBOARD_RESIZE = 'HANDLE_DASHBOARD_RESIZE';

export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const FETCH_REPORT_SUMMARY = 'FETCH_REPORT_SUMMARY';
export const SET_REPORT_SUMMARY = 'SET_REPORT_SUMMARY';
export const FETCH_ACTIVITY_REPORT = 'FETCH_ACTIVITY_REPORT';
export const SET_ACTIVITY_REPORT = 'SET_ACTIVITY_REPORT';

export const FETCH_ONBOARDING_STATUS = 'FETCH_ONBOARDING_STATUS';
export const SET_ONBOARDING_STATUS = 'SET_ONBOARDING_STATUS';
export const PUT_ONBOARDING_STATUS = 'PUT_ONBOARDING_STATUS';

export const FETCH_PINNED_BOARDS = 'FETCH_PINNED_BOARDS';
export const SET_PINNED_BOARDS = 'SET_PINNED_BOARDS';
export const FETCH_BOARDS = 'FETCH_BOARDS';
export const SET_BOARDS = 'SET_BOARDS';
export const POST_BOARD = 'POST_BOARD';
export const CLONE_BOARD = 'CLONE_BOARD';
export const PUT_BOARD = 'PUT_BOARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const SET_ACTIVE_BOARD = 'SET_ACTIVE_BOARD';

export const SET_BOARD_FILTERS = 'SET_BOARD_FILTERS';
export const CLEAR_BOARD_FILTERS = 'CLEAR_BOARD_FILTERS';

export const ADD_WIDGETS_TO_BOARD = 'ADD_WIDGETS_TO_BOARD';
export const UPDATE_BOARD_LAYOUT = 'UPDATE_BOARD_LAYOUT';
export const REMOVE_WIDGET_FROM_BOARD = 'REMOVE_WIDGET_FROM_BOARD';
export const USE_TEMPLATE_FOR_BOARD = 'USE_TEMPLATE_FOR_BOARD';
export const SET_BOARD_WIDGETS = 'SET_BOARD_WIDGETS';

export const FETCH_WIDGETS = 'FETCH_WIDGETS';
export const SET_WIDGETS = 'SET_WIDGETS';

export const FETCH_DASHBOARD_WIDGETS = 'FETCH_DASHBOARD_WIDGETS';
export const SET_DASHBOARD_WIDGETS = 'SET_DASHBOARD_WIDGETS';

export const FETCH_BOARD_TEMPLATES = 'FETCH_BOARD_TEMPLATES';
export const SET_BOARD_TEMPLATES = 'SET_BOARD_TEMPLATES';

export const FETCH_WIDGET_DATA = 'FETCH_WIDGET_DATA';
export const SET_WIDGET_DATA = 'SET_WIDGET_DATA';
export const SEARCH_PANEL_CONTACTS = 'SEARCH_PANEL_CONTACTS';

export const UPDATE_TENANT_CONFIG = 'UPDATE_TENANT_CONFIG';
export const SAVE_TENANT_CONFIG = 'SAVE_TENANT_CONFIG';
export const FETCH_TENANT_DATA = 'FETCH_TENANT_DATA';
export const SET_TENANT_DATA = 'SET_TENANT_DATA';
export const REFRESH_SYNC_TENANT_DATA = 'REFRESH_SYNC_TENANT_DATA';
export const MAPPED_NOTE = 'MAPPED_NOTE';
export const ADD_NOTE_ACTION = 'ADD_NOTE_ACTION';

export const SET_OUTREACH_ONBOARDING = 'SET_OUTREACH_ONBOARDING';