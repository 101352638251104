import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { Tooltip, Typography, Box, FormControlLabel, Switch, Stack } from '@mui/material';

import UserGridActions from './VoiceGridActions';
import { capitalizeFirstLetter, formatKey, isJson } from 'src/utils/helper';

import toast from '../../../../utils/toast';
import { toggleVisibilityOfProfileTab } from '../../actions/authActions';
import { useStyles } from './styles';
import { Button } from '../../../../components/shared';

export function voiceColumns(data, drawerToggle, props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [columns, setColumns] = useState([]);

  const handleVoiceStatusChange = async (voiceId, status) => {
    try {
      const rowData = { id: voiceId, status: status };
      const res = await props.updateVoiceStatus(rowData);
      if (res?.rowData) {
        const status = res?.rowData?.status === 'active' ? 'enabled' : 'disabled';
        toast.success(`Voice ${status} successfully`);
      }
    } catch (error) {}
  };

  const handleOpenEditModal = (editData) => {
    dispatch(toggleVisibilityOfProfileTab(true));
    const creationType =
      editData?.creationType ||
      (editData?.params?.tone?.length > 0 || editData?.sampleText === '' ? 'manual' : 'ai');
    props?.setOpenAICreateVoice({
      open: true,
      edit: editData,
      creationType,
      isPromptView: false,
    });
    props?.fetchVoiceDataById({ ...editData, creationType });
  };

  useEffect(() => {
    setColumns([
      {
        label: 'Status',
        name: 'status',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: false,
          draggable: true,
          setCellProps: () => ({
            style: { minWidth: 120, width: 40, maxWidth: 40, padding: 0 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            const [switchChecked, setSwitchChecked] = useState(rData?.status === 'active');

            useEffect(() => {
              setSwitchChecked(rData?.status === 'active');
            }, [rData]);

            return (
              <Box
                ml={'20px'}
                sx={{
                  '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: 'rgba(2, 136, 209, 0.7) !important',
                  },
                  '& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
                    backgroundColor: '#0288D1 !important',
                  },
                }}
              >
                {rData?.isDefault ? (
                  <Tooltip
                    title={
                      <Stack padding={'16px'} maxWidth={'372px'}>
                        <Stack gap="8px">
                          <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                            <Typography fontSize={'16px'} fontWeight={400}>
                              Voice enabled
                            </Typography>
                          </Box>
                          <Typography fontSize={'14px'} fontWeight={400}>
                            Voice is set as the default, so it cannot be disabled.
                          </Typography>
                        </Stack>
                      </Stack>
                    }
                    arrow
                    placement={'bottom'}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#082037',
                          '& .MuiTooltip-arrow': {
                            color: '#082037',
                          },
                        },
                      },
                    }}
                  >
                    {rData?.isDefault && (
                      <FormControlLabel
                        sx={{
                          display: 'block',
                        }}
                        control={
                          <Switch
                            checked={switchChecked}
                            onChange={(event) => {
                              const value = event.target.checked;
                              if (!rData?.isDefault) {
                                handleVoiceStatusChange(rData.id, value ? 'active' : 'inactive');
                                setSwitchChecked(event.target.checked);
                              }
                            }}
                            name="status"
                            color="secondary"
                          />
                        }
                        label=""
                      />
                    )}
                  </Tooltip>
                ) : (
                  <FormControlLabel
                    sx={{
                      display: 'block',
                    }}
                    control={
                      <Switch
                        checked={switchChecked}
                        onChange={(event) => {
                          const value = event.target.checked;
                          if (!rData?.isDefault) {
                            handleVoiceStatusChange(rData.id, value ? 'active' : 'inactive');
                            setSwitchChecked(event.target.checked);
                          }
                        }}
                        name="status"
                        color="secondary"
                      />
                    }
                    label=""
                  />
                )}
              </Box>
            );
          },
        },
      },
      {
        label: 'Name',
        name: 'name',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: true,
          draggable: true,
          setCellProps: () => ({
            style: { width: 250 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Box onClick={() => handleOpenEditModal(rData)} sx={{ cursor: 'pointer' }}>
                <Typography variant="body1" color="textPrimary" className={classes.nameTypography}>
                  {rData?.name}
                </Typography>
              </Box>
            );
          },
        },
      },
      {
        label: 'Description',
        name: 'description',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: false,
          draggable: true,
          setCellProps: () => ({
            style: { minWidth: 80, minHeight: 109 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];

            const truncatedDescription = () => {
              if (rData?.voicePrompt) {
                if (isJson(rData?.voicePrompt)) {
                  const voicePrompt = JSON.parse(rData.voicePrompt);
                  let combinedText = [];
                  const possibleToneKeys = ['tone_of_voice', 'Tone', 'TONE'];
                  const toneKey = possibleToneKeys.find((key) => voicePrompt?.[key]);
                  const characteristicsKey = Object.keys(voicePrompt || {}).find(
                    (key) =>
                      key.toLowerCase() === 'characteristics' ||
                      key.toLowerCase() === 'detailed_profile',
                  );
                  if (voicePrompt[toneKey]) combinedText.push(voicePrompt[toneKey]);
                  if (characteristicsKey) {
                    Object.entries(voicePrompt[characteristicsKey]).forEach(([key, value]) => {
                      if (Array.isArray(value)) {
                        const tempString = value.join(', ');
                        combinedText.push(`${formatKey(key)}: ${tempString}`);
                      } else {
                        combinedText.push(`${formatKey(key)}: ${value}`);
                      }
                    });
                  }
                  return combinedText.join('\n').length > 200
                    ? combinedText.join('\n').slice(0, 200) + '...'
                    : combinedText.join('\n');
                } else {
                  `${capitalizeFirstLetter(rData.voicePrompt).slice(0, 200)}${
                    rData.voicePrompt.length > 200 ? '...' : ''
                  }`;
                }
              } else {
                return rData?.params?.tone?.length > 0
                  ? rData?.params?.tone
                      ?.map((tone) => `${tone.charAt(0).toUpperCase() + tone.slice(1)}`)
                      .join(',')
                  : '-';
              }
            };

            const toneStyle = (key = 'tone') => {
              let data = rData?.params?.[key] || [];
              data = typeof data === 'string' ? data.split(',') : data;

              if (data.length === 0 && rData?.voicePrompt && isJson(rData?.voicePrompt)) {
                const voicePrompt = JSON.parse(rData.voicePrompt);

                data =
                  voicePrompt[key] && Array.isArray(voicePrompt[key])
                    ? voicePrompt[key]
                    : typeof voicePrompt[key] === 'string'
                    ? voicePrompt[key]?.split(',')
                    : [];
              }

              return (
                Array.isArray(data) &&
                data?.length > 0 && (
                  <div style={{ display: 'flex', gap: '5px', paddingTop: '4px' }}>
                    {data.slice(0, 3).map((item, index) => (
                      <Button
                        key={index}
                        variant="contained"
                        color="gradient"
                        sx={{ cursor: 'auto' }}
                        className={classes.gradientButtonManualCreate}
                      >
                        {`${item?.charAt(0)?.toUpperCase() + item?.slice(1)}`}
                      </Button>
                    ))}
                    {data.length > 3 && (
                      <Button
                        variant="outlined"
                        color="gradient"
                        onClick={() => handleOpenEditModal(rData)}
                        className={classes.gradientButtonManualCreate}
                      >
                        {`+${data.length - 3} more`}
                      </Button>
                    )}
                  </div>
                )
              );
            };

            return (
              <Box className={classes.description}>
                {toneStyle('style')}
                {toneStyle('tone')}
              </Box>
            );
          },
        },
      },
      {
        label: 'isDefault',
        name: 'isDefault',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: false,
          draggable: true,
          setCellProps: () => ({
            style: { width: 100 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <>
                {rData?.isDefault ? (
                  <Box mr={'32px'}>
                    <Typography className={classes.defaultTypography}>Default</Typography>
                  </Box>
                ) : (
                  ''
                )}
              </>
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          customHeadRender: () => null,
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellProps: () => ({
            style: { minWidth: 50, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = data.find((user) => user.id === value);
            return (
              <UserGridActions
                drawerToggle={drawerToggle}
                // status={row?.status}
                row={row}
                handleOpenEditModal={handleOpenEditModal}
                {...props}
              />
            );
          },
        },
      },
    ]);
  }, [data]);

  return columns;
}
