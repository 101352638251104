import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import images from '../../../../../config/images';
import { useStyles } from '../AiWriterModal/styles';
import { Button, Form } from '../../../../../components/shared';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const audienceOptions = {
  personal: 'Candidate',
  work: 'Customer',
  other: 'Other',
};

const AiSequenceGenerator = ({
  isVoiceLoading,
  fetchToneOfVoice,
  values,
  formProps,
  getPurposeOptions,
  onClose,
}) => {
  const classes = useStyles();
  const [showInstructions, setShowInstructions] = useState(false);
  return (
    <>
      <Box className={classes.modalHeader}>
        <img src={images.icons.magicAiIcon} alt="AI Icon" />
        <Box className={classes.modalTitleContainer}>
          <Typography className={classes.modalTitle}>Create a Sequence with AI</Typography>
          <Typography className={classes.modalSubTitle}>
            Generate tailored outreach sequences in seconds with AI-Powered precision.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.formContent}>
        <Box className={classes.formField}>
          <Typography className={classes.fieldLabel}>Choose your audience:</Typography>
          <Box display="flex" gap="16px">
            {Object.keys(audienceOptions).map((option) => {
              const isActive = values?.audienceEmailType === option;
              return (
                <Button
                  key={option}
                  onClick={() => {
                    if (isActive) return;
                    formProps.setFieldValue(
                      'audience',
                      audienceOptions[option] === 'Other' ? 'General' : audienceOptions[option],
                    );
                    formProps.setFieldValue('audienceEmailType', option);
                    formProps.setFieldValue('purpose', '');
                    formProps.setFieldValue('category', '');
                  }}
                  variant={isActive ? 'contained' : ''}
                  color={isActive ? 'gradient' : ''}
                  className={`${classes.audienceButton} ${classes.grayButton}`}
                >
                  {audienceOptions[option]}
                </Button>
              );
            })}
          </Box>
        </Box>

        <Box className={`${classes.formField}`}>
          <Typography className={classes.fieldLabel}>
            Confirm the reason for your outreach:
          </Typography>
          <Box width={'100%'}>
            <Form.Field.Select
              sx={{
                my: '24px',
              }}
              options={getPurposeOptions(values) || []}
              placeholder={'Select purpose'}
              fullWidth
              size="small"
              variant="outlined"
              name={'category'}
              optLabel="label"
              optValue="value"
              className={classes.purpose}
              isSmall
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflow: 'auto',
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            />
          </Box>
        </Box>

        <Box className={`${classes.formField}`}>
          <Typography className={classes.fieldLabel} mb={3}>
            Choose your tone of voice:
          </Typography>
          <Box width="100%">
            <Form.Field.AutoComplete
              options={[]}
              formSx={{
                mb: '24px',
                mt: 0,
              }}
              fullWidth
              isSmall
              getOptionLabel={(option) => option.name || ''}
              className={classes.selector}
              clearOnEscape={true}
              name="toneOfVoice"
              placeholder="Select tone of voice"
              optLabel="name"
              optValue="id"
              variant="outlined"
              isAiWriter={true}
              addNewLabel={'Create first voice'}
              remoteMethod={(val) => fetchToneOfVoice(val)}
              allowAdd={true}
              clearIcon={<span onClick={() => fetchToneOfVoice('')}>&times;</span>}
              loading={isVoiceLoading}
              multiple={false}
            />
          </Box>
        </Box>

        <Box className={`${classes.formField} ${classes.selector}`}>
          <Box display="flex" gap="8px">
            <AddCircleOutlineRoundedIcon
              onClick={() => setShowInstructions(true)}
              color="secondary"
              cursor="pointer"
            />
            <Box width="300px">
              <Typography
                onClick={() => setShowInstructions(!showInstructions)}
                className={classes.additionalInstructionsLabel}
                color="secondary"
              >
                Add additional instructions (optional)
              </Typography>
              {showInstructions && (
                <Typography paddingTop="8px" className={classes.additionalInstructionsText}>
                  Add any specific details you'd like included, such as key points, desired length,
                  or unique offers. This helps create a sequence and content that’s spot-on for your
                  needs.
                </Typography>
              )}
            </Box>
          </Box>
          {showInstructions && (
            <Form.Field.Input
              fullWidth
              multiline
              rows="5"
              variant="outlined"
              name="additionalInstructions"
              size="small"
              isSmall
              sx={{ mt: 0 }}
            />
          )}
        </Box>
      </Box>
      <Divider />

      <Box className={classes.modalHeader} justifyContent="flex-end">
        <Button onClick={onClose} variant="text" className={classes.cancelButton}>
          Cancel
        </Button>
        <Button className={classes.generateButton} type="submit">
          <img src={images.icons.magicIcon} alt="AI Icon" /> Generate
        </Button>
      </Box>
    </>
  );
};
export default AiSequenceGenerator;
