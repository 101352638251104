import React, { useEffect, useState, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import api from 'src/api';
import { Grid, Box } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import {
  fetchTeamsLookup,
  fetchOfficesLookup,
  fetchTimezoneLookup,
} from 'src/modules/admin/api/adminApi';
import toast from 'src/utils/toast';
import { accessLevelPermissions, permissionLevels } from './config';
import { useSelector } from 'react-redux';
import { fetchAllSubscriptions } from 'src/modules/admin/api/billingApi';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { emailRegex } from '../../../../config';

const subcriptionListObj = {
  'LMS-Annual-upfront-GBP-Yearly': ['lmsannual'],
  'LMS-12-monthly-payments-GBP-Monthly': ['lmsmonthly'],
  'Outreach-GBP-Monthly': ['outreachMonthly'],
  'OutreachAnnual-GBP-Yearly': ['outreachAnnual'],
  'One-Membership-GBP-Yearly': ['lmsannual', 'outreachAnnual'],
  'One-Membership-Monthly-GBP-Monthly': ['outreachMonthly', 'lmsmonthly'],
};

function UserForm({
  drawerToggle,
  editUser,
  formData,
  total,
  loading,
  userData,
  plansData,
  openSubscriptionPage,
  ...props
}) {
  const [defaultCandidate, setDefaultCandidate] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [userEditPopover, setUserEditPopover] = useState(false);
  const [directSubscription, setDirectSubscription] = useState(false);
  const [plans, setPlans] = useState([]);
  const tenant = useSelector((state) => state.app.tenant);
  const globalConfig = useSelector((state) => state.app.globals);
  const user = useSelector((state) => state.auth.user);

  const [step, setStep] = useState(openSubscriptionPage);
  const [licencedValues, setLicencedValues] = useState({
    inviteEmail: editUser?.inviteEmail ?? true,
  });

  useEffect(() => {
    if (editUser?.id === user?.id && editUser?.tenants && editUser?.tenants?.length > 0) {
      const subscriptionDetailObj = editUser?.tenants[0]?.subscription;
      let outreachType,
        lmsType = '';
      Object.keys(subscriptionDetailObj).forEach((key) => {
        if (subscriptionDetailObj[key]?.status === 'active') {
          if (subcriptionListObj.hasOwnProperty(key)) {
            subcriptionListObj[key].forEach((ele) => {
              if (ele === 'outreachMonthly' || ele === 'outreachAnnual') {
                outreachType = ele;
              } else if (ele === 'lmsmonthly' || ele === 'lmsannual') {
                lmsType = ele;
              }
            });
          }
        }
      });
      setLicencedValues((prevState) => ({
        ...prevState,
        outreachType,
        lmsType,
      }));
    }
  }, [editUser]);

  useEffect(() => {
    const fetchData = async () => {
      const timezoneOptions = await searchCandidates('', 'timezone', total);
      if (timezoneOptions?.length > 0) setTimezoneOptions(timezoneOptions);
      fetchAllSubscriptions()
        .then((res) => {
          setPlans(res.plans);
        })
        .catch((err) => {
          setPlans([]);
        });
    };
    fetchData();
    setUserEditPopover(!!editUser?.id && openSubscriptionPage === 0);
  }, []);
  const formRef = useRef();
  const editMode = editUser?.id;

  const titleList = useMemo(() => {
    return props?.globals?.titlesList || [];
  }, [props.globals]);

  useEffect(() => {
    if (editMode) {
      setDefaultCandidate(editUser);
    } else {
      setDefaultCandidate({ ...defaultCandidate, timezone: user.timezone, office: user.office });
    }
  }, [editUser]);

  const searchCandidates = async (search, lookupType, total) => {
    let res = [];
    if (lookupType === 'team') {
      res = await fetchTeamsLookup(search, total);
    } else if (lookupType === 'office') {
      res = await fetchOfficesLookup(search, total);
    } else if (lookupType === 'timezone') {
      res = await fetchTimezoneLookup(search);
    }
    return res || [];
  };

  const handleSubmit = async (values, form) => {
    setSubmitLoading(true);
    const data = {
      ...values,
      ...licencedValues,
    };

    if (values.team.id) {
      data.team = values.team.id ? [values.team.id] : [];
    } else {
      delete data.team;
    }
    if (values.office.id) {
      data.office = values.office.id ? [values.office.id] : [];
    } else {
      delete data.office;
    }
    data.timezone = values?.timezone?.value || 'UGT';
    data.inviteEmail = true;

    data.isAdmin = values.accessLevel === 'admin';
    data.acl = {
      accessLevel:
        values.accessLevel?.toLowerCase() === 'standard' ? 'Standard' : values.accessLevel || '',
    };
    data.permission =
      values.accessLevel?.toLowerCase() === 'standard' ? 'user' : values.accessLevel || '';
    delete data.accessLevel;
    try {
      if (editMode) {
        const updatedRow = { id: editUser?.id, data: data };
        await props.putUser(updatedRow);
      } else {
        await props.saveUser(data);
      }
    } finally {
      setSubmitLoading(false);
      setDirectSubscription(false);
      drawerToggle({ edit: null, open: false });
    }
  };

  const isSubscriptionSelected = ['lmsType', 'outreachType'].some((key) => licencedValues[key]);

  const formDetails = formRef?.current?.values ?? {};
  const editUserDetails = {
    'First Name': formDetails?.fname ? formDetails.fname : '-',
    'Last Name': formDetails?.lname ? formDetails.lname : '-',
    Email: formDetails?.email ? formDetails.email : '-',
    'Job Title': formDetails?.jobTitle ? formDetails.jobTitle : '-',
    Team: formDetails?.team?.value ? formDetails.team.value : '-',
    Office: formDetails?.office?.value ? formDetails.office.value : '-',
    'Time Zone': formDetails?.timezone?.value ? formDetails.timezone.value : '-',
    'Permission Level:': formDetails?.accessLevel ? formDetails.accessLevel : '-',
  };
  const formAccess = licencedValues ?? {};
  const editUserSubscriptionDetails = {
    ...(Object.keys(plansData?.outreach?.plans ?? {})?.length
      ? {
          Outreach:
            formAccess?.outreachType === 'outreachAnnual'
              ? 'Yearly'
              : formAccess?.outreachType === 'outreachMonthly'
              ? 'Monthly'
              : '-',
        }
      : {}),
    ...(Object.keys(plansData?.lms?.plans ?? {})?.length
      ? {
          Learning:
            formAccess?.lmsType === 'lmsannual'
              ? 'Yearly'
              : formAccess?.lmsType === 'lmsmonthly'
              ? 'Monthly'
              : '-',
        }
      : {}),
    ...(['lmsannual', 'lmsmonthly']?.includes(formAccess?.lmsType)
      ? {
          'Learning Content:': Object.keys(formAccess?.lmsCategories ?? {})?.reduce(
            (acc, category) => {
              if (formAccess?.lmsCategories[category]) {
                if (acc === '-') acc = '';
                acc += `${acc && ','} ${capitalizeFirstLetter(category)}`;
              }
              return acc;
            },
            '-',
          ),
        }
      : {}),
  };

  return (
    <Form
      initialValues={{
        title: defaultCandidate?.title ?? '',
        fname: defaultCandidate.fname ?? '',
        lname: defaultCandidate.lname ?? '',
        email: defaultCandidate.email ?? '',
        status: defaultCandidate.status ?? 'active',
        jobTitle: defaultCandidate.jobTitle ?? '',
        timezone: defaultCandidate?.timezone
          ? timezoneOptions?.find((ele) => ele.value === defaultCandidate?.timezone)
          : tenant?.timezone
          ? timezoneOptions?.find((ele) => ele.value === tenant?.timezone)
          : {},
        accessLevel: defaultCandidate?.acl?.accessLevel ?? '',
        team: defaultCandidate?.team
          ? {
              id: defaultCandidate?.team && defaultCandidate?.team[0]?.id,
              value: defaultCandidate?.team && defaultCandidate?.team[0]?.name,
            }
          : {},
        office: defaultCandidate?.office
          ? {
              id: defaultCandidate?.office && defaultCandidate?.office[0]?.id,
              value: defaultCandidate?.office && defaultCandidate?.office[0]?.name,
            }
          : {},

        taskNotifications: defaultCandidate?.taskNotifications ?? false,
      }}
      validationSchema={
        directSubscription
          ? Yup.object().shape({})
          : Yup.object().shape({
              email: Yup.string()
                .email('Please enter a valid email address.')
                .matches(emailRegex, 'Please enter a valid email')
                .test({
                  message: () => 'Email already exists.',
                  test: (values) => isEmailExist(values, defaultCandidate?.email),
                })
                .required('Please enter email.'),
              jobTitle: Yup.string()
                .trim()
                .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, 'Only alphabets are allowed.')
                .required('Please enter job title.')
                .nullable(true),
              timezone: Yup.object()
                .required('Please choose a timezone.')
                .test({
                  message: () => 'Please choose a timezone.',
                  test: (item) => item?.value,
                }),
              fname: Yup.string()
                .trim()
                .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, 'Only alphabets are allowed.')
                .max(64, 'first name must be at most 64 characters')
                .required('Please enter first name.'),
              lname: Yup.string()
                .trim()
                .matches(/^[A-Za-z]+(\s[A-Za-z]+)*$/, 'Only alphabets are allowed.')
                .max(64, 'first name must be at most 64 characters')
                .required('Please enter last name.'),
            })
      }
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
      innerRef={formRef}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: 'calc(100% - 16px)' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
              gap={'24px'}
            >
              <Box>
                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="fname"
                  label="First name"
                  type="text"
                />
                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="lname"
                  label="Last name"
                  type="text"
                />

                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="jobTitle"
                  label="Job title"
                />
                <Form.Field.Input
                  fullWidth
                  rows={4}
                  variant="outlined"
                  name="email"
                  label="Email"
                  type="email"
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="team"
                      label="Team"
                      placeholder="Team"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return searchCandidates(val, 'team', total);
                      }}
                      optLabel="value"
                      optValue="id"
                      multiple={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="office"
                      label="Office"
                      placeholder="Office"
                      variant="outlined"
                      remoteMethod={(val) => {
                        return searchCandidates(val, 'office', total);
                      }}
                      optValue="id"
                      optLabel="value"
                      multiple={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.AutoComplete
                      options={[]}
                      fullWidth
                      name="timezone"
                      label="Timezone"
                      placeholder="Timezone"
                      variant="outlined"
                      optLabel="value"
                      remoteMethod={(val) => {
                        return searchCandidates(val, 'timezone', total);
                      }}
                      optValue="id"
                      multiple={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.Select
                      fullWidth
                      options={permissionLevels}
                      variant="outlined"
                      name="accessLevel"
                      label="Permission Level"
                      placeholder="Access Level"
                      optLabel="label"
                      optValue="value"
                      showNone={false}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flex: 1 }} />
              {step === 0 && (
                <Box display="flex" mt={0} justifyContent="flex-end" width="100%">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => drawerToggle({ edit: null, open: false })}
                    loading={loading.users}
                  >
                    Cancel
                  </Button>
                  <Spacer basis={2} />
                  <Button
                    variant="contained"
                    color="secondary"
                    loading={loading.users}
                    onClick={async () => {
                      const errors = await formRef?.current?.validateForm();
                      if (Object.keys(errors)?.length) {
                        await formRef?.current?.submitForm();
                        toast.error('Please enter all required details');
                      }
                    }}
                    type={'submit'}
                  >
                    {editUser?.id ? 'Update' : 'Save'}
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

const isEmailExist = async (values, currentEmail, loading) => {
  if (values === currentEmail) return true;
  try {
    let response = await api(`/user?email=${values}`, null, 'get');
    if (response.users.length > 0) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};

export default UserForm;
