import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/shared';
import GatedModal from 'src/modules/app/components/OutreachOnBoarding/GatedModal';
import {
  CustomGatedFooterContent,
  CustomGatedMainContent,
} from 'src/modules/app/components/OutreachOnBoarding/styles';
import images from 'src/config/images';
import { useGuideModalStyles } from 'src/modules/app/components/OutreachOnBoarding/FeatureGuide/styles';
import { updateUser } from 'src/modules/auth/api/authApis';
import { setCurrentUser } from 'src/modules/auth/actions/authActions';

const guideStepList = [
  {
    key: 'dataCreditIntro',
    title: 'Unlock Reliable Contact Data',
    description:
      "Access accurate emails and phone numbers from the top data providers—all through SuperReach's exclusive contracts. One platform, no extra subscriptions.",
  },
  {
    key: 'creditUsage',
    title: 'Smarter Data, Automatically',
    description:
      'We’ve set up a default waterfall for you to get started. We rank providers in real time, prioritising the best performers to get you the most accurate results.',
  },
  {
    key: 'monitoring',
    title: 'Tailor to Your Strategy',
    description:
      "Turn off providers, reorder them, and create a setup that works for you. It's all in your hands.",
  },
];

const WaterFallGuideModal = ({ onClose }) => {
  const theme = useTheme();
  const classes = useGuideModalStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const currentGuide = guideStepList[currentStep];

  useEffect(() => {
    setIsOpen(!user?.outreachOnboarding?.waterFallSteps);
  }, []);

  const handleLmsBoarding = async (isSkip, isClose) => {
    if (isSkip) return;

    try {
      const payload = {
        outreachOnboarding: {
          ...user?.outreachOnboarding,
          waterFallSteps:
            isClose || !isNext
              ? {
                  dataCreditIntro: false,
                  creditUsage: false,
                  monitoring: false,
                }
              : {
                  ...(user?.outreachOnboarding?.waterFallSteps || {}),
                  [currentGuide.key]: true,
                },
        },
      };

      const res = await updateUser({
        id: user.id,
        data: payload,
      });
      dispatch(setCurrentUser({ userData: res }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextStep = () => {
    if (currentStep < guideStepList.length - 1) {
      setCurrentStep((prev) => prev + 1);
      handleClose(null, true);
    } else {
      handleClose();
    }
  };

  const handleClose = (e, isNext = false) => {
    if (!isNext) {
      setIsOpen(false);
      if (onClose) onClose();
    }

    const guideSteps = user?.outreachOnboarding?.waterFallSteps;
    const isSkip =
      guideSteps?.[currentGuide.key] ||
      (guideSteps && Object.keys(guideSteps).length === guideStepList.length);

    handleLmsBoarding(isSkip, !isNext);
  };

  return (
    <GatedModal
      className={classes.root}
      open={isOpen}
      onCloseModal={handleClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      image={images.onboarding[`waterFallModalStep${currentStep + 1}`]}
      imageStyle={{ backgroundColor: '#FFFFFF' }}
      renderFooter={
        <CustomGatedFooterContent>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" flex={1}>
            <Stack flexDirection="row" gap={2}>
              <Button variant="text" color="primary" onClick={handleClose}>
                <Typography fontSize="inherit" color={theme.palette.text.black1}>
                  {currentStep === guideStepList.length - 1 ? 'Later' : 'Skip'}
                </Typography>
              </Button>
              <Button variant="contained" color="primary" onClick={handleNextStep}>
                {currentStep === 0
                  ? "Got It! Let's Go"
                  : currentStep === 2
                  ? "Let's Customise"
                  : 'Next'}
              </Button>
            </Stack>
          </Stack>
        </CustomGatedFooterContent>
      }
    >
      <CustomGatedMainContent>
        <Stack gap={2}>
          <Typography className="title">{currentGuide.title}</Typography>
          <Typography>{currentGuide.description}</Typography>
        </Stack>
      </CustomGatedMainContent>
    </GatedModal>
  );
};

WaterFallGuideModal.propTypes = {
  onClose: PropTypes.func,
};

export default WaterFallGuideModal;
