import { all, fork, put, call, select, takeLatest } from 'redux-saga/effects';
import * as appTypes from '../actions/appTypes';
import * as appApis from '../api/appApis';
import * as appActions from '../actions/appActions';
import { isEmpty } from 'lodash';

function* fetchReportSummary({ filter }) {
  try {
    let filters = {
      ...(filter?.createdAtRange
        ? {
            createdAt_gte: filter.createdAtRange.startDate,
            createdAt_lte: filter.createdAtRange.endDate,
          }
        : {}),
    };

    const [sequence, task, contact, email] = yield all([
      call(appApis.fetchSequencesReport, filters),
      call(appApis.fetchTaskReport, filters),
      call(appApis.fetchContactsReport, filters),
      call(appApis.fetchEmailReport, filters),
    ]);
    yield put(appActions.setReportSummary({ sequence, task, contact, email }));
  } catch (error) {}
}

function* fetchActivityReport({ paging, filters, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.createdAtRange
        ? {
            createdAt_gte: filters.createdAtRange.startDate,
            createdAt_lte: filters.createdAtRange.endDate,
          }
        : {}),
      ...filters,
    };
    delete filter?.createdAtRange;
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }
    const response = yield call(appApis.fetchActivityReport, filter);
    yield put(appActions.setActivityReport(response.report, response.total.value));
  } catch (error) {}
}

export function* watchSagas() {
  yield takeLatest(appTypes.FETCH_REPORT_SUMMARY, fetchReportSummary);
  yield takeLatest(appTypes.FETCH_ACTIVITY_REPORT, fetchActivityReport);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
