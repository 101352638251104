import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, Typography } from '@mui/material';
import Spacer from '../../shared/Spacer';

import { styled } from '@mui/system';

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '40px 20px',
}));

const AppLoader = ({ title, description, sx = {} }) => {
  return (
    <LoaderWrapper sx={sx}>
      <CircularProgress size={30} color="secondary" />
      <Spacer basis={3} />
      {title !== '' && (
        <Typography variant="body1" color="textSecondary" textAlign="center">
          {title}
        </Typography>
      )}
      <Spacer basis={0.5} />
      {description !== '' && (
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      )}
    </LoaderWrapper>
  );
};

AppLoader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

AppLoader.defaultProps = {
  title: '',
  description: '',
};

export default AppLoader;
