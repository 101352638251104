import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/styles';
import { useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    backgroundColor: '#F9F9F9',
  },
  '& .Mui-focused': {
    backgroundColor: '#F9F9F9',
  },
});

const LocationAutocomplete = ({ setFieldValue, name, classes, item, errors, defaultValue }) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const autocompleteService = useRef(null);

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions({ input: value }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setOptions(predictions);
        }
      });
    } else {
      setOptions([]);
    }
  };

  const fetchPlaceDetails = (finalValue) => {
    const locationValue = finalValue[finalValue?.length - 1];
    if (typeof locationValue === 'string') {
      if (defaultValue?.value?.length > 0) {
        const isContainData = defaultValue?.value?.find((item) => item.label === locationValue);
        if (isContainData) {
          setFieldValue(name, {
            value: finalValue?.filter((item) => item.label !== locationValue),
            type: 'multiple',
          });
          return;
        } else {
          setFieldValue(name, {
            value: [...(finalValue || [])],
            type: 'multiple',
          });
          return;
        }
      }
    }

    const placeId = finalValue[finalValue?.length - 1]?.place_id;

    if (placeId) {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );

      placesService.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const { lat, lng } = place.geometry.location;
          const locationData = {
            label: place.formatted_address,
            place_id: place.place_id,
            lat: lat(),
            lng: lng(),
          };
          setFieldValue(name, {
            value: [
              ...(finalValue?.map((item) =>
                item?.place_id === locationData?.place_id ? { ...locationData } : item,
              ) || []),
            ],
            type: 'multiple',
          });
        }
      });
    }
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      placeholder={item?.placeholder || `Select ${item?.label.toLowerCase()}`}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          debugger;
          e.preventDefault();
          const inputValue = e?.target?.value?.trim();
          if (inputValue) {
            setFieldValue(name, {
              value: [...(defaultValue?.value || []), { label: inputValue }],
              type: 'multiple',
            });
          }
        }
      }}
      error={!!errors[item.fieldName]}
      InputProps={{
        ...params.InputProps,
        endAdornment: inputValue && options.length ? params.InputProps.endAdornment : null,
      }}
    />
  );

  return (
    <CustomAutocomplete
      freeSolo
      forcePopupIcon
      fullWidth
      multiple
      limitTags={1}
      placeholder={item?.placeholder || `Select ${item?.label.toLowerCase()}`}
      options={
        options.map((option) => ({
          label: option.description,
          place_id: option.place_id,
        })) || []
      }
      onInputChange={(event, newInputValue) => {
        handleInputChange({ target: { value: newInputValue } });
      }}
      renderInput={renderInput}
      onChange={(event, value, situation) => {
        if (situation === 'removeOption') {
          setFieldValue(name, {
            value: value,
            type: 'multiple',
          });
          return;
        }
        if (value?.length > 0) {
          fetchPlaceDetails(value);
        }
      }}
      ChipProps={{
        deleteIcon: (
          <CloseIcon
            sx={{
              cursor: 'pointer',
              color: '#1976D2 !important',
              fontSize: '16px',
              marginTop: '2px !important',
            }}
          />
        ),
        sx: {
          borderRadius: '5px',
          background: '#F0F7FF',
          fontSize: '14px',
          fontWeight: '400',
          maxWidth: '225px !important',
        },
      }}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          backgroundColor: theme.palette.text.white1,
        },
        '& .Mui-focused': {
          backgroundColor: theme.palette.text.white1,
        },
      }}
      className={classes}
      name={name}
      size="small"
      variant="outlined"
      value={defaultValue?.value || []}
      defaultValue={defaultValue?.value || []}
    />
  );
};

export default LocationAutocomplete;
