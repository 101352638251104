import React, { useCallback, useState } from 'react';
import { Box, InputAdornment, Stack, TextField, Typography, Pagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';

import images from 'src/config/images';
import { Button, Spacer } from '../../../../components/shared';
import ICPGrid from './ICPGrid';
import EmptyIcp from './EmptyIcp';
import { useStyles } from './style';

const ICPView = (props) => {
  const [showICPForm, setShowICPForm] = useState({
    isOpen: false,
    edit: false,
    clone: false,
    id: null,
  });
  const [icpSearch, setICPSearch] = useState('');
  const classes = useStyles();
  const handleChange = useCallback(
    debounce(
      (newValue) =>
        props.fetchICP(
          props.icp.paging,
          { ...props.icp.filters, _search: newValue },
          props.icp.sort,
        ),
      500,
    ),
    [props.fetchICP, props.icp.paging, props.icp.filters, props.icp.sort],
  );

  const handlePageChange = (event, newPage) => {
    props.fetchICP({ ...props.icp.paging, pageNo: newPage - 1 }, props.icp.filters, props.icp.sort);
  };

  return (
    <Stack gap={0.5} mb={2}>
      <Spacer y={0.5} />
      <Box display={'flex'} justifyContent={'space-between'}>
        <TextField
          variant="outlined"
          placeholder="Search ICP"
          size="small"
          className={classes.textFieldContainer}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { borderRadius: 4 },
          }}
          value={icpSearch}
          onChange={(event) => {
            event.persist();
            setICPSearch(event.target.value);
            handleChange(event.target.value);
          }}
          sx={{ width: 300 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowICPForm({ isOpen: true, edit: false, clone: false, id: null })}
          startIcon={<img src={images.icons.plusIcon} alt={''} />}
          className={classes.createICPButton}
        >
          <Typography
            variant="body2"
            sx={{ padding: '8px 16px 8px 0px', fontWeight: '500 !important' }}
          >
            Create new ICP
          </Typography>
        </Button>
      </Box>
      {props?.loading?.icp ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={'150px'}>
          <CircularProgress color="inherit" size={24} />
        </Box>
      ) : (
        <>
          <ICPGrid
            showICPForm={showICPForm}
            setShowICPForm={setShowICPForm}
            saveICP={props.saveICP}
            icpsData={props.icp}
            getICPById={props.getICPById}
            updateICP={props.updateICP}
            deleteICP={props.deleteICP}
            {...props}
          />
          {props.icp?.data && props.icp.data.length > 0 && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={Math.ceil((props.icp.paging.count || 0) / props.icp.paging.perPage)}
                page={props.icp.paging.pageNo + 1}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </>
      )}
      {props?.icp?.data && props.icp.data.length < 1 && !props?.loading?.icp && (
        <EmptyIcp icpSearch={props.icp?.filters?._search} setShowICPForm={setShowICPForm} />
      )}
    </Stack>
  );
};

export default ICPView;
