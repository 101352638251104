import React, { useEffect, useState, useMemo } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Drawer, Menu } from 'src/components/shared';

import {
  WaitTimeForm,
  LinkedinForm,
  EmailForm,
  CallbackForm,
  TaskForm,
  SmsForm,
} from '../../SequenceEvents';
import AIWriterForm from '../../SequenceEvents/SequenceFormTabBar/AIWriterForm';
import { stepTypes } from '../../../utils/sequenceUtils';
import { useSelector } from 'react-redux';

const showEmailTypeSelection = [];

function AddNew({
  open,
  hasEndSeq,
  onClose,
  onSubmit,
  action,
  event,
  addStepIndex,
  events,
  template,
}) {
  const user = useSelector((state) => state.auth.user);
  const [formType, setFormType] = useState(undefined);
  const [formLoading, setFormLoading] = useState(undefined);
  const [linkedinType, setLinkedinType] = useState('linkedinMessage');
  const [openAIWriter, setOpenAIWriter] = useState(false);
  const [saveAsNew, setSaveAsNew] = useState(false);
  const [selectedAiResponse, setSelectedAiResponse] = useState(null);

  useEffect(() => {
    const linkedinTypes = [
      'linkedinConnection',
      'linkedinMail',
      'linkedinViewProfile',
      'linkedinMessage',
    ];
    if (linkedinTypes.includes(formType)) {
      setLinkedinType(formType);
    }
  }, [formType]);

  const handleClose = () => {
    setLinkedinType('');
    setOpenAIWriter(false);
    setSaveAsNew(false);
    setSelectedAiResponse(null);
    setFormType(undefined);
    onClose();
  };

  useEffect(() => {
    if (action === 'edit' && event?.id && event?.id !== '') {
      setFormType(event.eventType);
    }
    if (action === 'addTemplate' && event?.id && event?.id !== '') {
      setFormType('email');
    }
  }, [action, event]);

  const filterStepsType = useMemo(() => {
    if (Object.values(events).length <= 0 || hasEndSeq) {
      return stepTypes.filter((step) => step.type !== 'endsquence');
    }
    return stepTypes;
  }, [events, hasEndSeq]);

  const handleSubmit = async (values) => {
    const payload = { ...values };
    setFormLoading(true);
    if (!payload?.assignedto || payload?.assignedto?.length === 0) payload.assignedto = [user.id];
    else payload.assignedto = payload.assignedto.map((item) => item.id);
    await onSubmit(payload);
    if (
      (Object.keys(events).length === 0 && payload.eventType !== 'endsquence') ||
      (!hasEndSeq && payload.eventType !== 'endsquence')
    ) {
      await onSubmit({
        eventType: 'endsquence',
        eventOrder: Object.keys(events).length === 0 ? 1 : Object.keys(events).length + 1,
      });
    }
    setFormLoading(false);
    handleClose();
  };

  const getContent = () => {
    switch (formType) {
      case 'wait':
        return (
          <WaitTimeForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'email':
        return (
          <EmailForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
            action={action}
            template={template}
            openAIWriter={openAIWriter}
            setOpenAIWriter={setOpenAIWriter}
            saveAsNew={saveAsNew}
            setSaveAsNew={setSaveAsNew}
            selectedAiResponse={selectedAiResponse}
            setSelectedAiResponse={setSelectedAiResponse}
          />
        );
        break;

      case 'linkedin':
      case 'linkedinConnection':
      case 'linkedinMessage':
      case 'linkedinMail':
      case 'linkedinViewProfile':
        return (
          <LinkedinForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
            linkedinType={linkedinType}
            openAIWriter={openAIWriter}
            setOpenAIWriter={setOpenAIWriter}
            saveAsNew={saveAsNew}
            setSaveAsNew={setSaveAsNew}
            selectedAiResponse={selectedAiResponse}
            setSelectedAiResponse={setSelectedAiResponse}
          />
        );
        break;

      case 'call':
        return (
          <CallbackForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'general':
        return (
          <TaskForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'sms':
        return (
          <SmsForm
            onSubmit={handleSubmit}
            handleClose={handleClose}
            loading={formLoading}
            event={event}
          />
        );
        break;

      case 'endsquence':
      default:
        break;
    }
  };

  const getTitle = () => {
    switch (formType) {
      case 'wait':
        return 'Wait period';
        break;

      case 'email':
        return saveAsNew ? 'Create new email template' : 'Email';
        break;

      case 'linkedinConnection':
        return saveAsNew ? 'Create new LinkedIn template' : 'LinkedIn connection';
        break;

      case 'linkedinViewProfile':
        return saveAsNew ? 'Create new LinkedIn template' : 'LinkedIn view profile';
        break;

      case 'linkedinMail':
        return saveAsNew ? 'Create new LinkedIn template' : 'LinkedIn InMail';
        break;

      case 'linkedinMessage':
        return saveAsNew
          ? 'Create new LinkedIn template'
          : `LinkedIn message (${event.mode === 'automation' ? 'Automated' : 'Manual'})`;

      case 'linkedin':
        return saveAsNew ? 'Create new LinkedIn template' : 'LinkedIn';
        break;

      case 'call':
        return 'Call back';
        break;

      case 'sms':
        return 'SMS';
        break;

      case 'general':
        return 'Reminder';
        break;

      case 'endsquence':
      default:
        return 'End sequence';
        break;
    }
  };

  const sendEndSequence = () => {
    const data = {
      eventType: 'endsquence',
    };
    handleSubmit(data);
  };

  const sendSelectEmail = () => {
    const data = {
      eventType: 'selectEmailTemplate',
    };
    handleSubmit(data);
  };

  const onSelect = (step) => {
    if (step.isAction) {
      if (step.type === 'endsquence') {
        sendEndSequence();
      }
      if (step.type === 'selectEmailTemplate') {
        sendSelectEmail();
      }
    } else {
      if (step.type === 'linkedin') {
        setLinkedinType('linkedinMessage');
      }
      setFormType(step.type);
    }
  };

  const isDisabled = (step) => {
    if (step.type === 'endsquence') {
      if (hasEndSeq === true) {
        return true;
      }
      if (Object.values(events).length > addStepIndex + 1) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Drawer
        open={open}
        disableEnforceFocus
        title={!formType ? 'Select steps' : getTitle()}
        showBack={!formType ? false : true}
        onClose={handleClose}
        disabledPadding={!formType ? true : false}
        onBackClick={() => {
          setOpenAIWriter(false);
          setSelectedAiResponse(null);
          if (saveAsNew) {
            setSaveAsNew(false);
          } else {
            setSaveAsNew(false);
            setFormType(undefined);
          }
        }}
        size={'mid'}
        sx={{
          '& .drawer-header': {
            padding: '16px 24px',
          },
        }}
        contentStyle={{
          padding: '0px 24px',
          position: 'relative',
        }}
      >
        {formType ? (
          <>{getContent()}</>
        ) : (
          <List disablePadding={true}>
            {filterStepsType.map((step, index) => {
              return (
                <ListItem
                  key={`add-step-type-${index}`}
                  button
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={() => {
                    onSelect(step);
                  }}
                  // disabled={hasEndSeq === true && step.type === 'endsquence'}
                  disabled={isDisabled(step)}
                >
                  <ListItemIcon>{step.icon}</ListItemIcon>
                  <ListItemText primary={step.title} secondary={step.desc} />
                </ListItem>
              );
            })}
          </List>
        )}
      </Drawer>
      <AIWriterForm
        formType={formType}
        showSelect={showEmailTypeSelection?.includes(formType)}
        open={openAIWriter}
        setOpenAIWriter={setOpenAIWriter}
        selectedAiResponse={selectedAiResponse}
        setSelectedAiResponse={setSelectedAiResponse}
        linkedinType={linkedinType}
      />
    </>
  );
}

export default AddNew;
