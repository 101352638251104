import styled from "@emotion/styled";
import { Stack } from "@mui/material";

export const CustomGatedMainContent = styled(Stack)(({ theme }) => ({
  gap: "24px",

  [theme.breakpoints.down("md")]: {
    width: "max-content",
    maxWidth: "100%",
    marginInline: "auto",
  },

  ".title": {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "160%",

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },

  ".description": {
    gap: "24px",

    ".item": {
      flexDirection: "row",
      alignItems: "center",
      gap: "16px",

      strong: {
        whiteSpace: "nowrap",
        fontWeight: "700",
      },

      svg: {
        minWidth: 32,
      },

      p: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "175%",
        letterSpacing: "0.15px",
        whiteSpace: "pre",

        [theme.breakpoints.down("md")]: {
          fontSize: 14,
          fontWeight: "700",
          whiteSpace: "normal",

          "& strong": {
            fontSize: 14,
          },
        },
      },
    },
  },
}));

export const CustomGatedHeaderContent = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  gap: "8px",

  ".title": {
    color: theme.palette.text.main,
    fontSize: "36px",
    fontWeight: "200",
    lineHeight: "120%",
    letterSpacing: "0.15px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off, 'clig' off",
    whiteSpace: "pre",

    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },

    strong: {
      fontWeight: "700",
    },
  },

  ".description": {
    color: theme.palette.text.muted,
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "175%",
    letterSpacing: "0.15px",
  },
}));

export const CustomGatedFooterContent = styled(Stack)(({ theme }) => ({
  "& button": {
    [theme.breakpoints.down("md")]: {
      borderRadius: "999px",
    },
  },
}));

