import { useCallback, useRef } from 'react';
import { debounce } from 'lodash';

export const useSingleDebouncedFunction = (defaultWaitTime = 1000) => {
  const debounceRef = useRef();

  const debouncedFunction = useCallback(
    (fn, waitTime) => {
      if (debounceRef.current?.cancel) {
        debounceRef.current.cancel();
      }

      const newDebounce = debounce(fn, waitTime || defaultWaitTime);
      debounceRef.current = newDebounce;

      return newDebounce;
    },
    [defaultWaitTime],
  );

  const cancel = useCallback(() => {
    if (debounceRef.current?.cancel) {
      debounceRef.current.cancel();
    }
  }, []);

  return { debouncedFunction, cancel };
};