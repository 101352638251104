import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Card, ConfirmDialog } from 'src/components/App';
import { Button, Modal, Spacer } from 'src/components/shared';
import CloseIcon from '@mui/icons-material/Close';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { useStyles } from '../../../admin/containers/integrations/styles';
import { useSelector } from 'react-redux';
import Datepicker from './../../../../components/shared/Form/Datepicker/index';
import Input from './../../../../components/shared/Form/Input/index';
import images from 'src/config/images';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const timeOptions = {
  0: 'Today',
  1: 'Tomorrow',
  7: '7 days time',
  30: '30 days time',
  60: '60 days time',
};

const IntegrationsCards = ({ disconnectIntegration, saveRescheduleEmail }) => {
  const userData = useSelector((state) => state?.auth?.user);
  const tenantIntegration = [
    {
      type: 'enrich',
      name: 'Gmail',
      // src: '/gmail.png',
      url: `${process.env.REACT_APP_BASE_API_URL}/api/user/nlauth?service=gmail`,
      icon: <img src={images.app.gmail} style={{ width: 44, marginTop: 8 }} />,
    },
    {
      type: '',
      name: 'Office 365',
      url: `${process.env.REACT_APP_BASE_API_URL}/api/user/nlauth?service=microsoft`,
      // src: '/office.png',
      icon: <img src={images.app.office} style={{ width: 40 }} />,
    },
    {
      type: '',
      name: 'Others',
      url: `${process.env.REACT_APP_BASE_API_URL}/api/user/nlauth?service=other`,
      icon: <LocalPostOfficeOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: 48 }} />,
    },
  ];
  const [integrationData, setSelectedIntegrationData] = useState({});
  const [open, setOpen] = useState(false);
  const [reconnectLinkModalOpen, setReconnectLinkModalOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [connection, setConnection] = useState({});
  const [customDate, setCustomDate] = useState(null);
  const [integrationPayload, setIntegrationPayload] = useState(false);
  const classes = useStyles();
  const integrations = { data: [] };
  const { data } = integrations;

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (id, status) => {
    setIntegrationPayload({ id: id, status: status });
    setOpen(true);
  };
  const onConfirm = () => {
    if (integrationPayload) {
      disconnectIntegration(integrationPayload.id, integrationPayload.status);
      setOpen(false);
    }
  };

  useEffect(() => {
    // Dead code below
    if (data?.length > 0) {
      data.map((value) => {
        if (value?.name === 'Bullhorn') {
          const newIntegrationData = tenantIntegration.filter((integration) => {
            if (integration.name === 'Bullhorn') {
              integration.integration = value.integration;
              integration.status = value.status;
              integration.id = value.id;
              return integration;
            }
          });
          setSelectedIntegrationData(newIntegrationData);
        }
      });
    }
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const onClickUrl = (status, url) => {
    if (status === 'inactive') return openInNewTab(url);
  };

  const closeConnectMadal = () => {
    setReconnectLinkModalOpen(false);
  };

  const formatDate = (date) => {
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return formatter.format(date);
  };

  const onReconnect = async () => {
    let dateToformat = '';
    if (selectedTime !== 'custom')
      dateToformat = new Date(new Date().getTime() + selectedTime * 24 * 60 * 60 * 1000);
    else dateToformat = new Date(customDate);
    const date = formatDate(dateToformat);
    setReconnectLinkModalOpen(false);
    onClickUrl('inactive', connection?.url);
    saveRescheduleEmail(date);
  };

  const {
    nlProvider: provider,
    nlConnectedAt: connectedDate,
    nlAccessToken: accessToken,
    nlSyncState,
  } = userData ?? {};
  const isSynced = !(nlSyncState === 'stopped' || nlSyncState === 'invalid');
  const type = ['eas', 'ews', 'graph', 'hotmail', 'exchange', 'office365', 'outlook', 'microsoft']?.includes(
    provider,
  )
    ? 'Office 365'
    : provider === 'gmail'
    ? 'Gmail'
    : !!provider
    ? 'Others'
    : null;

  const isAnyConnected = tenantIntegration?.find(
    (interList) => type === interList.name && accessToken && isSynced,
  );

  return (
    <Box className={classes.gridContainer}>
      {tenantIntegration.map((interList, index) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = new Date(connectedDate)?.toLocaleDateString('en-US', options);

        const isActive = type === interList.name && accessToken && isSynced;
        const reConnect =
          type === interList.name && (!accessToken || nlSyncState !== 'running') && !isActive;
        const status = isActive ? 'active' : 'inactive';

        return (
          <Card
            key={`interList-${interList.mainTitle}-${interList.id}-${index}`}
            sx={isAnyConnected && !isActive ? { filter: 'grayScale(1)' } : {}}
            className={classes.mainCardIntegrations}
            cardContent={{
              className: classes.cardContentIntegrationCard,
              children: (
                <Box p={4}>
                  <Box display="flex" gap={2} alignItems="flex-start">
                    <Box>{interList.icon}</Box>
                    <Box>
                      <Typography fontSize={20} fontWeight={400} color="rgba(0, 0, 0, 0.75)">
                        {interList.name}
                      </Typography>

                      <Typography variant="body2" color="textSecondary">
                        {isActive ? `Connected on ${formattedDate}` : 'Not connected'}
                      </Typography>
                      <Stack direction="row" gap={2}>
                        {reConnect && (
                          <Button
                            onClick={() => {
                              setConnection(interList);
                              setReconnectLinkModalOpen(true);
                            }}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: 8 }}
                            startIcon={<AutorenewIcon />}
                          >
                            Reconnect
                          </Button>
                        )}
                        {!reConnect && !isActive && (
                          <Button
                            onClick={() => {
                              onClickUrl(status, interList.url);
                            }}
                            disabled={isAnyConnected}
                            color="secondary"
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: 8 }}
                          >
                            Connect
                          </Button>
                        )}
                        {isActive && (
                          <Button
                            onClick={() => {}}
                            color="success"
                            variant="contained"
                            size="small"
                            sx={{
                              marginTop: 8,
                              padding: '8px 22px 8px 13px',
                              backgroundColor: '#4CAF50',
                            }}
                            startIcon={<CheckCircleOutlineIcon />}
                          >
                            Connected
                          </Button>
                        )}
                      </Stack>
                      {isActive && (
                        <Button
                          onClick={() => handleClickOpen('', status)}
                          color="secondary"
                          variant="outlined"
                          size="small"
                          sx={{ marginTop: 8, border: 'none' }}
                        >
                          Disconnect
                        </Button>
                      )}
                      <ConfirmDialog
                        title="Gmail/Office 365"
                        open={open}
                        onClose={handleClose}
                        onConfirm={onConfirm}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <Typography variant="body1" color="textSecondary">
                            Please note : this action will halt all outgoing emails
                          </Typography>
                        </Box>
                      </ConfirmDialog>
                    </Box>
                  </Box>
                </Box>
              ),
            }}
          />
        );
      })}

      <Modal open={reconnectLinkModalOpen} onClose={closeConnectMadal} size="xs">
        <Box className={classes.connectionModal}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography fontSize={18} fontWeight={500}>
                Schedule
              </Typography>
              <Typography fontSize={14}>Select when to restart sending emails</Typography>
            </Box>
            {/* <Box onClick={closeConnectMadal}>
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.25)',
                  '&:hover': { color: 'rgba(0, 0, 0, 0.5)' },
                }}
              />
            </Box> */}
          </Box>
          <Box display="flex" flexDirection="column">
            {Object.keys(timeOptions).map((time, index) => (
              <Box
                key={`integration-card-${index}`}
                className="time-input"
                onClick={() => setSelectedTime(time)}
                color={selectedTime === time ? '#000' : '#81868B'}
                bgcolor={selectedTime === time ? 'rgba(2, 136, 209, 0.2);' : 'transparent'}
                sx={
                  selectedTime !== time && {
                    '&:hover': { background: 'rgba(2, 136, 209, 0.12);', color: '#000' },
                  }
                }
              >
                {timeOptions[time]}
                {selectedTime === time && <DoneIcon style={{ color: '#1976D2' }} />}
              </Box>
            ))}
            <Box
              className="time-input"
              onClick={() => setSelectedTime('custom')}
              color={selectedTime === 'custom' ? '#000' : '#81868B'}
              bgcolor={selectedTime === 'custom' ? 'rgba(2, 136, 209, 0.2);' : 'transparent'}
              sx={
                selectedTime !== 'custom' && {
                  '&:hover': { background: 'rgba(2, 136, 209, 0.12);', color: '#000' },
                }
              }
            >
              Custom
              {false && <DoneIcon style={{ color: '#1976D2' }} />}
            </Box>
          </Box>
          {selectedTime === 'custom' && (
            <React.Fragment>
              <Input value={customDate ? formatDate(customDate) : null} />
              <Datepicker
                fullWidth
                disablePast
                isStatic
                variant="inline"
                name="customDate"
                value={customDate}
                onChange={setCustomDate}
                label="Custom Date"
              />
            </React.Fragment>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Box className="submit-btn" onClick={onReconnect}>
              Save
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default IntegrationsCards;
